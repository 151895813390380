import React, {useEffect, useState} from "react";
import {Box, Button, DateInput, Form, FormField, Layer, Select, TextInput} from "grommet";
import {EventServiceId, EventType, ExcludeEventTypes} from "../../types/EventType";
import {User} from "../../types/User";
import SectionForm from "../../common/SectionForm";
import {VolunteerCheckIn} from "../../types/CheckInEvent";
import VolunteerCheckInItem from "./VolunteerCheckInItem";
import {v4 as uuid} from "uuid";
import {getGuestVolunteer, getOrganizations, postGuestVolunteer, postOrganization} from "../../Api/volunteerApi";
import {useQuery, useQueryClient} from "react-query";
import {deleteCheckin, getCheckIns} from "../../Api/api";
import {HourLengthOption, MinuteLengthOption} from "../../types/VolunteerTimeTypes";
import {dataFormat} from "../../types/Constants";


const Volunteer: React.FC = () => {
    const [checkedInEvents, setCheckedInEvents] = useState<VolunteerCheckIn[]>([]);
    const [area, setArea] = useState("");
    const [organization, setOrganization] = useState("");
    const [organizations, setOrganizations] = useState<string[]>([]);
    const [phoneNumber, setPhoneNumber] = useState("");
    const [email, setEmail] = useState("");
    const [minuteLength, setMinuteLength] = useState(MinuteLengthOption.ZERO);
    const [hourLength, setHourLength] = useState(HourLengthOption.ZEROHOURS);
    const [showLayer, setShowLayer] = useState(false);
    const [selectedDate, setSelectedDate] = useState(new Date().toLocaleDateString());
    const [newOrganization, setNewOrganization] = useState("");
    const [reset, setReset] = useState(false);
    const [user, setUser] = useState<User | null>(null);

    const queryClient = useQueryClient();

    const excludeEventTypes = [...ExcludeEventTypes, EventType.VOLUNTEER, EventType.REPORT];

    const startDay = new Date();
    startDay.setHours(0, 0, 0, 0);

    // Get the end of the current day
    const endDay = new Date();
    endDay.setHours(23, 59, 59, 999);

    const deleteEvent = (event: VolunteerCheckIn) => {
        deleteCheckin(event).then((response) => {
            if(response.message === "Success"){
                setCheckedInEvents(checkedInEvents.filter(e => e !== event));
            }
        });
    };

    const fetchVolunteerCheckIn = async (): Promise<VolunteerCheckIn[]> => {
        return await getGuestVolunteer(startDay, endDay);
    }

    const {isError: checkInError, data: volunteerCheckInEvents } =
        useQuery<VolunteerCheckIn[], Error>(`Checkins-${EventServiceId[EventType.VOLUNTEER]}`, fetchVolunteerCheckIn, {
            staleTime: 1000*60*10, //ten minutes
            cacheTime: 1000*60*10, //ten minutes
        });

    const { isError: organizationError, data: apiOrganizations } =
        useQuery<string[], Error>(`Organizations`, getOrganizations, {
            staleTime: 1000 * 60 * 10, // ten minutes
            cacheTime: 1000*60*10, //ten minutes
        });

    useEffect(() => {
        if(!organizationError && apiOrganizations){
            setOrganizations(apiOrganizations);
        }
    }, [apiOrganizations, organizationError]);

    useEffect(() => {
        if(!checkInError && volunteerCheckInEvents){
            setCheckedInEvents(volunteerCheckInEvents);
        }
    }, [volunteerCheckInEvents, checkInError]);


    const getVolunteerDetails = async (guestId: string): Promise<string> => {
        try {
            // Get today's date
            const endDay: Date = new Date();

            // Calculate one month ago from today
            const startDay: Date = new Date("2000-01-01T00:00:00");

            // Fetch the volunteer check-ins
            const checkIns = await getCheckIns(startDay, endDay, EventServiceId[EventType.VOLUNTEER], guestId) as VolunteerCheckIn[]

            // Sort the check-ins by date in descending order to get the most recent first
            const sortedCheckIns: VolunteerCheckIn[] = checkIns.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());

            // Process the most recent check-in if the list is not empty
            if (sortedCheckIns.length > 0) {
                const mostRecentCheckIn: VolunteerCheckIn = sortedCheckIns[0];

                setEmail(mostRecentCheckIn.email);
                setPhoneNumber(mostRecentCheckIn.phone);
                setArea(mostRecentCheckIn.area);
                setOrganization(mostRecentCheckIn.organization);
            } else {
                return ""; // Return an empty string if the list is empty
            }

            return ""; // Return an empty string if no error occurs
        } catch (error) {
            return "An error occurred while fetching volunteer details."; // Return an error message if an exception is caught
        }
    };

    const addNewOrganization = () => {

        // Capitalize the first letter of each word in newOrganization
        const capitalizedNewOrganization = newOrganization.replace(/(\b[a-z](?!\s))/g, (match) => match.toUpperCase());

        postOrganization(capitalizedNewOrganization).then(async (response: any) => {
            if (response.message === "Success") {
                await queryClient.invalidateQueries(["Organizations"]);
                setOrganizations([...organizations, capitalizedNewOrganization]);
            }
        });

        setNewOrganization("");
        setShowLayer(false);
    };

    const resetForm = () => {
        setArea("");
        setOrganization("");
        setMinuteLength(MinuteLengthOption.ZERO);
        setHourLength(HourLengthOption.ZEROHOURS);
        setSelectedDate(new Date().toLocaleDateString());
    };

    const handleSubmit = () => {
        // Handle submission here
        if (user === null){
            return;
        }

        // Format the phone number to '(999) 999-9999'
        let formattedPhoneNumber = phoneNumber;
        //check to see if a number was entered
        if (phoneNumber !== undefined) {

            // Extract all digits from phoneNumber
            const digits = phoneNumber.match(/\d/g);

            // Reformat to '(999) 999-9999' only if there are exactly 10 digits
            if (digits && digits.length === 10) {
                formattedPhoneNumber = `(${digits.slice(0, 3).join('')}) ${digits.slice(3, 6).join('')}-${digits.slice(6).join('')}`;
            }
        }



        const selectedDateObject = new Date(selectedDate);
        const currentTime = new Date(); // Current date and time

        // Set the time parts from the current time to the selected date
        selectedDateObject.setHours(currentTime.getHours());
        selectedDateObject.setMinutes(currentTime.getMinutes());
        selectedDateObject.setSeconds(currentTime.getSeconds());
        selectedDateObject.setMilliseconds(currentTime.getMilliseconds());



        const newEvent: VolunteerCheckIn = {
            CheckInID: uuid(),
            serviceID: EventType.VOLUNTEER,
            date: selectedDateObject, // Selected date with current time
            guest: user,
            area: area as EventType,
            organization: organization,
            hours: hourLength,
            minutes: minuteLength,
            phone: formattedPhoneNumber,
            email: email,
        };

        postGuestVolunteer(newEvent).then(async (response: any) => {
            if (response.message === "Success") {
                await queryClient.invalidateQueries([`Checkins-${EventServiceId[EventType.VOLUNTEER]}`]);
                setCheckedInEvents([...checkedInEvents, newEvent]);
            }
        });

        setReset(!reset);
    }

    const validateDate = (dateString: string): string | undefined => {
        const dateObject = new Date(dateString);
        const currentDate = new Date();

        // Remove time from currentDate to only compare the date part
        currentDate.setHours(0, 0, 0, 0);

        if (isNaN(dateObject.getTime())) {
            return "Invalid date. Please enter a valid date.";
        }

        if (dateObject > currentDate) {
            return "Can't be in the future";
        }

        return undefined;
    };

    const validateTimeVolunteered = (hourLength: HourLengthOption, minuteLength: MinuteLengthOption): string | undefined => {
        if ((hourLength === HourLengthOption.ZEROHOURS || hourLength == null) && (minuteLength === MinuteLengthOption.ZERO || minuteLength == null)) {
            return "Must enter a time over 0 minutes.";
        }
        return undefined;
    };

    const validateNonEmpty = (value: string): string | undefined => {
        if (value === "") {
            return "This field is required.";
        }
        return undefined;
    }

    function validatePhone(phoneNumber: string): string | undefined {

        if (phoneNumber === "" || phoneNumber === undefined) {
            return undefined;
        }

        const allDigits = phoneNumber.match(/\d/g) || [];

        // Check if phone number has more than 10 digits
        if (allDigits.length > 10 || allDigits.length < 10) {
            return "Invalid phone number.";
        }

        // If none of the above, then the phone number is valid
        return undefined;
    }


    function validateEmail(email: string): string | undefined{

        if (email === "" || email === undefined) {
            return undefined;
        }

        // This pattern matches most common email formats
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9-]+\.[a-zA-Z]{2,}$/;

        if (emailRegex.test(email)) {
            return undefined;
        } else {
            return "Invalid email format";
        }
    }


    return (
        <Box overflow={'auto'}>
            <SectionForm
                eventType={EventType.VOLUNTEER}
                CheckInEventItem={VolunteerCheckInItem}
                initialCheckInEvents={checkedInEvents}
                onUserSelected={setUser}
                getDetails={getVolunteerDetails}
                deleteEvent={deleteEvent}
                reset={reset}
            >
                <Box width="medium" align="center" fill={"horizontal"}>
                    <Form onSubmit={() => { handleSubmit(); resetForm(); }}>
                        <FormField name="area" label="Working Area" validate={() => validateNonEmpty(area)}>
                            <Select
                                placeholder="Choose an area"
                                options={Object.values(EventType).filter(
                                    eventType => !excludeEventTypes.includes(eventType))}
                                value={area}
                                onChange={({ option }) => setArea(option)}
                            />
                        </FormField>

                        <FormField name="organization" label="Individual/Church/Organization" validate={() => validateNonEmpty(organization)}>
                            <Select
                                placeholder="Choose an organization"
                                options={organizations}
                                value={organization}
                                onChange={({ option }) => setOrganization(option)}
                            />
                        </FormField>

                        <Box direction="row" justify="end">
                            <Button margin={{vertical: "small"}} label="New Organization" onClick={() => setShowLayer(true)} />
                        </Box>

                        <FormField name="timeVolunteered" label="Hours Volunteered" validate={() => validateTimeVolunteered(hourLength, minuteLength)}>
                            <Box direction="row" gap="small" pad={{vertical: "small"}}>
                                <Select
                                    placeholder="Hours"
                                    options={Object.values(HourLengthOption)}
                                    value={hourLength}
                                    onChange={({ option }) => setHourLength(option)}
                                />
                                <Select
                                    placeholder="Minutes"
                                    options={Object.values(MinuteLengthOption)}
                                    value={minuteLength}
                                    onChange={({ option }) => setMinuteLength(option)}
                                />
                            </Box>
                        </FormField>

                        <FormField name="dateVolunteered" label="Date Volunteered" validate={() => validateDate(selectedDate)}>
                            <DateInput
                                format={dataFormat}
                                value={selectedDate}
                                onChange={(event) => setSelectedDate(event.value as string)}
                            />
                        </FormField>

                        <FormField name="phoneNumber" label="Phone (Optional)" validate={() => validatePhone(phoneNumber)}>
                            <TextInput
                                value={phoneNumber}
                                onChange={(event) => setPhoneNumber(event.target.value)}
                            />
                        </FormField>

                        <FormField name="email" label="Email (Optional)" validate={() => validateEmail(email)}>
                            <TextInput
                                value={email}
                                onChange={(event) => setEmail(event.target.value)}
                            />
                        </FormField>


                        <Box justify="end" direction="row" pad={{ vertical: 'medium' }}>
                            <Button type="submit" label="Submit" primary />
                        </Box>

                    </Form>
                    {showLayer && (
                        <Layer onEsc={() => setShowLayer(false)} onClickOutside={() => setShowLayer(false)}>
                            <Box pad="medium">
                                <Form onSubmit={addNewOrganization}>
                                    <FormField name="newOrganization" label="New Organization">
                                        <TextInput
                                            placeholder="Organization name"
                                            value={newOrganization}
                                            onChange={event => setNewOrganization(event.target.value)}
                                        />
                                    </FormField>
                                    <Button type="submit" label="Add Organization" primary />
                                </Form>
                            </Box>
                        </Layer>
                    )}
                </Box>
            </SectionForm>
        </Box>
    );
};

export default Volunteer;

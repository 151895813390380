import {Box, Button, Heading, Layer, Text} from "grommet";
import React, { ReactNode } from 'react';

interface ErrorLayerProps {
    errorMessage: ReactNode | string
    onOverride: () => void;
    onCancel: () => void;
}

const ErrorLayer: React.FC<ErrorLayerProps> = ({ errorMessage, onOverride, onCancel }) => {
    return (
        <Layer onEsc={onCancel}>
            <Box pad="medium" gap="small">
                <Heading level={3} margin="none">
                    Oops
                </Heading>
                {typeof errorMessage === 'string' ? <Text>{errorMessage}</Text> : errorMessage}
                <Box direction="row" justify="between">
                    <Button
                        label="Override"
                        onClick={onOverride}
                        primary
                    />
                    <Button
                        label="Cancel"
                        onClick={onCancel}
                        primary
                    />
                </Box>
            </Box>
        </Layer>
    )
};

export default ErrorLayer;

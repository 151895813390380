// src/components/Shower.tsx
import React, {useState} from "react";
import CheckinForm from "../../common/CheckIn/CheckinForm";
import {EventServiceId, EventType} from "../../types/EventType";
import {User} from "../../types/User";
import {checkinGuest} from "../../Api/api";
import CheckInList from "../../common/CheckIn/CheckInList";
import {v4 as uuid} from "uuid";

const Laundry: React.FC = () => {
    const [checkedInTrigger, setCheckedInTrigger] = useState(false);

    const handleCheckin = async (user: User | null) => {
        if (user) {
            await checkinGuest(user.GuestID, EventServiceId[EventType.LAUNDRY], uuid())

            setCheckedInTrigger(!checkedInTrigger);
        }
    };

    return (
        <CheckinForm eventType={EventType.LAUNDRY} onCheckin={handleCheckin}>
            <CheckInList eventType={EventType.LAUNDRY} reRenderTrigger={checkedInTrigger}/>
        </CheckinForm>
    );
};

export default Laundry;

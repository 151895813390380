import React, {useMemo} from 'react';
import { Box, Text } from 'grommet';
import Chart from "react-apexcharts";
import CheckinEventHandler from "../CheckinEventHandler";

const calculatePercentile = (data: number[], percentile: number): number => {
    data.sort((a, b) => a - b);
    const index = percentile / 100. * data.length;
    if (Math.floor(index) === index) {
        return (data[index - 1] + data[index]) / 2;
    }
    return data[Math.floor(index)];
};

type Bin = { min: number, max: number, label: string };

const createHistogramData = (values: number[], bins: Bin[]): { x: string; y: number }[] => {
    const frequencyMap: Record<string, number> = {};

    values.forEach((value: number) => {
        for (const bin of bins) {
            if ((value >= bin.min) && (value <= bin.max || bin.max === Infinity)) {
                frequencyMap[bin.label] = (frequencyMap[bin.label] || 0) + 1;
                break;
            }
        }
    });

    return bins.map(bin => ({
        x: bin.label,
        y: frequencyMap[bin.label] || 0,
    }));
};

const ageBins: Bin[] = [
    { min: 0, max: 19, label: '0-19' },
    { min: 20, max: 29, label: '20-29' },
    { min: 30, max: 39, label: '30-39' },
    { min: 40, max: 49, label: '40-49' },
    { min: 50, max: 59, label: '50-59' },
    { min: 60, max: 69, label: '60-69' },
    { min: 70, max: 79, label: '70-79' },
    { min: 80, max: Infinity, label: '80+' },
];


const HistogramComponent: React.FC = () => {

    let ageData = CheckinEventHandler.getInstance().getAgeData()

    const histogramData = useMemo(() => createHistogramData(ageData, ageBins), [ageData]);

    const series = [{ name: "", data: histogramData}];

    const options = {
        chart: {
            type: 'bar',
            height: "100%"
        },
        yaxis: {
            title: {
                text: 'Number of People',
            }
        },
        title: {
            text: "Age Distribution",
            align: 'left',
        },
        legend: {
            position: 'bottom'
        }
    };

    return (
        <>
            <Box
                fill
                pad={"medium"}
            >
                <Chart
                    options={options}
                    series={series}
                    type="bar"
                    width="100%"
                    height="100%"
                />
            </Box>

            <Box direction={"row"} justify={"center"} gap={"large"} pad={"medium"}>
                <Box justify={"center"} align={"center"}>
                    <Text>{Math.min(...ageData)}</Text>
                    <Text>Min</Text>
                </Box>
                <Box justify={"center"} align={"center"}>
                    <Text>{ calculatePercentile(ageData, 25)}</Text>
                    <Text>25th</Text>
                </Box>
                <Box justify={"center"} align={"center"}>
                    <Text>{ calculatePercentile(ageData, 50)}</Text>
                    <Text>Median</Text>
                </Box>
                <Box justify={"center"} align={"center"}>
                    <Text>{ calculatePercentile(ageData, 75)}</Text>
                    <Text>75th</Text>
                </Box>
                <Box justify={"center"} align={"center"}>
                    <Text>{Math.max(...ageData)}</Text>
                    <Text>Max</Text>
                </Box>
            </Box>
        </>
    );
};

export default HistogramComponent;

// src/components/MonthlyChart.tsx
import React, {useState, useEffect} from "react";
import {Box, Grid, Heading, Text} from "grommet";
import {ClothingType, ClothingTypeRestrictions} from "../../types/ClothingType";
import Popup from './Popup';
import {ClothingCheckIn} from "../../types/CheckInEvent";
import {categorizedClothingTypes} from "../../types/ClothingCatagories";

interface MonthlyChartProps {
    parentClothingEvents: ClothingCheckIn[];
    onCategoryClick: (category: string) => void;
    onDelete: (clothingCheckIn: ClothingCheckIn) => void;
    startDate: Date;
    endDate: Date;
    selectedCategory: string;
    reverse?: boolean;
}

const MonthlyChart: React.FC<MonthlyChartProps> = ({parentClothingEvents, onDelete, onCategoryClick, startDate, endDate, selectedCategory, reverse=false}) => {
    const [monthHeaders, setMonthHeaders] = useState<string[]>([]);
    const [organizedEvents, setOrganizedEvents] = useState<Record<string, ClothingCheckIn[]>>({});
    const [validityCounts, setValidityCounts] = useState<Record<string, number>>({});
    const [selectedEvent, setSelectedEvent] = useState<{ category: ClothingType, monthString: string, dates: Date[] } | null>(null);


    // Helper function to format a Date object into month-year string
    const formatDateToMonthYear = (date: Date): string => {
        return `${date.toLocaleString("default", {
            month: "short",
        })} ${date.getFullYear().toString().slice(-2)}`;
    };

    const organizeEventsByMonthAndCategory = (): Record<string, ClothingCheckIn[]> => {
        const organized: Record<string, ClothingCheckIn[]> = {};

        categorizedClothingTypes.forEach(categoryItem => {
            categoryItem.items.forEach(clothingType => {
                const key = `${monthHeaders[0]}-${clothingType}`;
                organized[key] = [];
            });
        });

        parentClothingEvents.forEach(event => {
            const key = `${formatDateToMonthYear(event.date)}-${event.category}`;
            if (!organized[key]) {
                organized[key] = [];
            }
            organized[key].push(event);
        });

        return organized;
    };


    const addValidity = (): Record<string, number> => {
        const counts: Record<string, number> = {};

        parentClothingEvents.forEach(event => {



            const key = `${formatDateToMonthYear(event.date)}-${event.category}`;
            const periodInMonths = ClothingTypeRestrictions[event.category].periodInMonths;

            if (!counts[key]) {
                counts[key] = 0;
            }

            const date = new Date(event.date);
            date.setDate(1);

            for (let i = 0; i < periodInMonths; i++) {


                const newKey = `${formatDateToMonthYear(date)}-${event.category}`;
                if (!counts[newKey]) {
                    counts[newKey] = 0;
                }
                counts[newKey]++;
                date.setMonth(date.getMonth() + 1);
            }
        });

        return counts;
    };


    useEffect(() => {
        let headers: string[] = [];
        let current = new Date(startDate);

        while (current <= endDate) {
            headers.push(formatDateToMonthYear(current));
            current = new Date(current.setMonth(current.getMonth() + 1));
        }

        if (reverse) {
            headers = headers.reverse();
        }

        setMonthHeaders(headers);
        setOrganizedEvents(organizeEventsByMonthAndCategory());
        setValidityCounts(addValidity());

    }, [startDate, endDate, parentClothingEvents]); // eslint-disable-line react-hooks/exhaustive-deps


    const baseColors = ["light-2", undefined];
    const selectedColors = ["blue-2", "blue-1"];

    function getBackgroundColor(rowIndex: number, isSelected: boolean): string | undefined {
        if (isSelected) {
            // If selected, use the appropriate selected color
            return selectedColors[rowIndex % 2];
        } else {
            // Otherwise, use the base color
            return baseColors[rowIndex % 2];
        }
    }

    return (
        <Box>
            <Grid columns={["auto", ...monthHeaders.map(() => "auto")]}>
                <Box />
                {monthHeaders.map((month, index) => (
                    <Box key={month + index} align="center" margin={{horizontal:"small"}} style={{ minWidth: 'max-content' }}>
                        <Text weight="bold">{month}</Text>
                    </Box>
                ))}

                {categorizedClothingTypes.map(categoryItem => (
                    <>
                        <Box background={'light-4'}>
                            <Heading margin={"xsmall"} level={'3'}>{categoryItem.category}:</Heading>
                        </Box>

                        {monthHeaders.map((monthHeader, index) => {

                            return (
                                <Box
                                    key={monthHeader + index}
                                    align="center"
                                    background={'light-4'}
                                    focusIndicator={false}
                                >

                                </Box>
                            );
                        })}


                        {categoryItem.items.map((clothingType, rowIndex) => (
                            <>
                                <Box
                                    pad={{ start: "medium" }}
                                    background={getBackgroundColor(rowIndex, selectedCategory === clothingType)}
                                    style={{ minWidth: 'max-content' }}
                                    key={clothingType}
                                    onClick={() => {
                                        onCategoryClick(clothingType)
                                    }}
                                    focusIndicator={false}
                                    hoverIndicator={"dark-2"}
                                >
                                    <Text weight="bold">{clothingType}:</Text>
                                </Box>

                                {monthHeaders.map((monthHeader, index) => {
                                    const key = `${monthHeader}-${clothingType}`;
                                    const days = organizedEvents[key] || [];
                                    days.sort((a, b) => a.date.getTime() - b.date.getTime());
                                    const baseBackgroundColor = rowIndex % 2 === 0 ? "light-2" : undefined;
                                    const baseErrorColor = rowIndex % 2 === 0 ? "red-2" : "red-1";
                                    const baseValidColor = rowIndex % 2 === 0 ? "green-2" : "green-1";
                                    const isValid = validityCounts[key] && validityCounts[key] < ClothingTypeRestrictions[clothingType as ClothingType].maxTimes;
                                    const backgroundColor = isValid ? baseValidColor : (validityCounts[key] && validityCounts[key] >= ClothingTypeRestrictions[clothingType as ClothingType].maxTimes ? baseErrorColor : baseBackgroundColor);


                                    return (
                                        <Box
                                            onClick={days.length > 0 ? () => setSelectedEvent({ category: clothingType as ClothingType, monthString: monthHeader, dates: days.map(day => day.date)}): undefined}
                                            key={index}
                                            align="center"
                                            background={backgroundColor}
                                            focusIndicator={false}
                                        >
                                            <Text color={'black'}>
                                                {days.length
                                                    ? days.map(day => day.date.getDate()).join(" ")
                                                    : "-"
                                                }
                                            </Text>

                                        </Box>
                                    );
                                })}

                            </>
                        ))}
                    </>
                ))}


            </Grid>
            {selectedEvent && selectedEvent.dates.length > 0 && (
                <Popup
                    dates={selectedEvent.dates}
                    onDelete={(date) => {
                        // You may want to add logic here to remove the date from the list of dates
                        if (selectedEvent) {

                            //get the event that matches the date and category
                            const event = parentClothingEvents.find(event => event.date.getTime() === date.getTime() && event.category === selectedEvent.category);

                            if(!event){
                               return
                            }

                            onDelete(event)
                        }
                        setSelectedEvent(null)
                    }}
                    onClose={() => setSelectedEvent(null)}
                />
            )}
        </Box>
    );


};

export default MonthlyChart;

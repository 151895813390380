import React, {useEffect, useState} from 'react';
import Chart from 'react-apexcharts';
import {Box, Spinner, Text} from "grommet";
import CheckinEventHandler, {FiveNumberSummary} from "../CheckinEventHandler";
import {EventType} from "../../../types/EventType";
import {KitchenEvent} from "../../../types/CalendarEvent";



type KitchenDataType = {
    [category: string]: {
        [year: number]: {
            [month: number]: number[];
        };
    };
};

export const KitchenCountsPlot: React.FC= () => {
    const [showNoDataMessage, setShowNoDataMessage] = useState<boolean>(false);

    let rawKitchenData = CheckinEventHandler.getInstance().getSpecialEvents(EventType.KITCHEN) as KitchenEvent[];
    rawKitchenData = rawKitchenData || []

    // Category , Year, Month
    let kitchenData: KitchenDataType = {}

    function addData(categoryKey: string, dataObject: KitchenDataType, date: Date, value: number): void {
        const year = date.getFullYear();
        const month = date.getMonth() + 1; // JavaScript months are 0-indexed

        dataObject[categoryKey] = dataObject[categoryKey] || {};
        dataObject[categoryKey][year] = dataObject[categoryKey][year] || {};
        dataObject[categoryKey][year][month] = dataObject[categoryKey][year][month] || [];
        dataObject[categoryKey][year][month].push(value);
    }

    rawKitchenData.forEach(event => {
        const eventDate = new Date(event.Date);

        addData("AM", kitchenData, eventDate, event.PeopleServedAM);
        addData("PM", kitchenData, eventDate, event.PeopleServedPM);
        addData("TOTAL", kitchenData, eventDate, event.PeopleServedAM + event.PeopleServedPM);
    });

    function transformToStatisticalSummary(kitchenData: KitchenDataType): {[key: string]: {[key: number]: {[key: number]: FiveNumberSummary}}} {
        const transformedData: {[key: string]: {[key: number]: {[key: number]: FiveNumberSummary}}} = {};

        for (const category in kitchenData) {
            transformedData[category] = transformedData[category] || {};

            for (const year in kitchenData[category]) {
                transformedData[category][year] = transformedData[category][year] || {};

                for (const month in kitchenData[category][year]) {
                    const data = kitchenData[category][year][month];
                    transformedData[category][year][month] = CheckinEventHandler.computeFiveNumberSummary(data);
                }
            }
        }

        return transformedData;
    }

    let statisticalKitchenData = transformToStatisticalSummary(kitchenData);

    function reformatData(data: {[key: string]: {[key: number]: {[key: number]: FiveNumberSummary}}}, category: "AM" | "PM" | "TOTAL", year: number): {type:string, data:{ x: string, y: number[] | number }[]}[] {

        const reformattedData: { x: string, y: number[] }[] = [];
        const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];


        for (const month in data[category][year]) {
            const processedData = data[category][year][month];

            reformattedData.push({
                x: `${monthNames[parseInt(month) - 1]}`,
                y: [
                    processedData.lowerFence,
                    processedData.firstQuartileValue,
                    processedData.medianValue,
                    processedData.thirdQuartileValue,
                    processedData.upperFence,
                ]
            });

        }

        reformattedData.sort((a, b) => monthNames.indexOf(a.x) - monthNames.indexOf(b.x));

        return ([
            {
                type: 'boxPlot',
                data: reformattedData
            },
        ]);
    }

    useEffect(() => {
        const timer = setTimeout(() => {
            if (Object.keys(statisticalKitchenData).length === 0) {
                setShowNoDataMessage(true);
            }
        }, 10000); // 10 seconds timeout

        return () => clearTimeout(timer); // Clean up the timer
    }, [statisticalKitchenData]);

    const options = {
        chart: {
            type: 'boxplot',
            height: "100%"
        },
        title: {
            align: 'left',
            text: ''
        },
        yaxis: {
            title: {
                text: 'Number of Guests'
            }
        },
        xaxis: {
            labels: {
                rotate: -45,
                rotateAlways: true
            }
        }
    };

    const years = Object.keys(statisticalKitchenData["AM"] || []);
    const categories: Array<"AM" | "PM" | "TOTAL"> = ['AM', 'PM', 'TOTAL'];

    if(Object.keys(statisticalKitchenData).length !== 0){
        return (
            <Box fill={'horizontal'}>
                <h2>Kitchen Statistics</h2>
                {years.map(year => (
                    <Box pad={"medium"} justify={'center'}>
                        <h2>{year}</h2>
                        <Box key={year} direction={'row'} justify={"evenly"} fill={"vertical"}>
                            {categories.map(category => (
                                <Box
                                    width={{min: '300px', width: '30vw', max: '500px'}}
                                    height={{min: '300px', height: '30vw', max: '500px'}}
                                    flex={"grow"}
                                >
                                    <Chart
                                        options={{
                                            ...options,
                                            title: {
                                                ...options.title,
                                                text: `${category}`,
                                            },
                                        }}
                                        series={reformatData(statisticalKitchenData, category, parseInt(year))}
                                        type="boxPlot"
                                        width="100%"
                                        height="100%"
                                    />

                                </Box>
                            ))}
                        </Box>
                    </Box>
                ))}
            </Box>
        );
    }

    if (showNoDataMessage) {
        return (
            <Box fill={"horizontal"} align={"center"} justify={"center"} margin={{vertical:"xlarge"}}>
                <Text size={"large"}>No Kitchen Data Available</Text>
            </Box>
        );
    }

    return (
        <Box fill={"horizontal"} align={"center"} margin={{vertical:"xlarge"}}>
            <Spinner size={"large"}/>
            <Text size={"large"} margin={{top:"small"}}>Loading Kitchen Data...</Text>
        </Box>
    );

};

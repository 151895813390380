import {Box, DataTable, Heading} from "grommet";
import React from 'react';
import CheckinEventHandler from "./CheckinEventHandler";
import {EventType} from "../../types/EventType";

const KitchenDataSummary: React.FC = () => {

    let filteredData = CheckinEventHandler.getInstance().getSpecialEvents(EventType.KITCHEN)

    const columns = [
        { property: 'DateID', header: 'Date'},
        { property: 'PeopleServedAM', header: 'People Served AM' },
        { property: 'PeopleServedPM', header: 'People Served PM' },
        { property: 'Total', header: 'People Served Total', render: (rowData: any) => rowData.PeopleServedAM + rowData.PeopleServedPM }
    ];

    return (
        <Box style={{flexShrink: 0}}>
            <Heading level={2}>Kitchen Data Summary</Heading>
            <DataTable columns={columns} data={filteredData} paginate={true}/>
        </Box>

    );
}

export default KitchenDataSummary;


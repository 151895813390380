import {VolunteerCheckIn} from "../types/CheckInEvent";
import {Auth} from "aws-amplify";
import {EventServiceId, EventType} from "../types/EventType";
import {getCheckIns} from "./api";


export const postGuestVolunteer = async (volunteerCheckIn: VolunteerCheckIn) => {
    const session = await Auth.currentSession();
    const idToken = session.getIdToken().getJwtToken();

    const requestOptions = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${idToken}`,
        },
        body: JSON.stringify({
            GuestID: volunteerCheckIn.guest.GuestID,
            ServiceID: EventServiceId[EventType.VOLUNTEER],
            Date: Math.floor(volunteerCheckIn.date.getTime() / 1000),
            Area: volunteerCheckIn.area,
            Organization: volunteerCheckIn.organization,
            Hours: volunteerCheckIn.hours,
            Minutes: volunteerCheckIn.minutes,
            Phone: volunteerCheckIn.phone,
            Email: volunteerCheckIn.email,
        }),
    };

    try {
        const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/api/checkin/${volunteerCheckIn.CheckInID}/volunteer`, requestOptions);
        return await response.json();
    } catch (error) {
        console.error("Error during API call:", error);
    }
};


export const getGuestVolunteer = async (startDate: Date, endDate: Date): Promise<VolunteerCheckIn[]> => {
    const checkIns = await getCheckIns(startDate, endDate, EventServiceId[EventType.VOLUNTEER]);
    return checkIns.map(item => item as VolunteerCheckIn);
};


export const postOrganization = async (organizationName: string) => {
    const session = await Auth.currentSession();
    const idToken = session.getIdToken().getJwtToken();

    const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/api/organizations`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${idToken}`,
        },

        body: JSON.stringify({
            Name: organizationName,
        }),
    });

    if (!response.ok) {
        const responseBody = await response.json();
        throw new Error(responseBody.error || `HTTP error! status: ${response.status}`);
    }

    return response.json();
};


export const getOrganizations = async (): Promise<string[]> => {
    const session = await Auth.currentSession();
    const idToken = session.getIdToken().getJwtToken();

    const requestOptions = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${idToken}`,
        },
    };

    const url = new URL(`${process.env.REACT_APP_API_ENDPOINT}/api/organizations`);

    try {
        const response = await fetch(url, requestOptions);
        const rawData = await response.json();

        // Map raw data to a list of strings, each being the 'Name' of an organization
        return rawData.map((event: any) => event.Name);

    } catch (error) {
        console.error("Error during API call:", error);
        return [];
    }
};



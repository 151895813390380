import React from 'react';
import {Box, Grid, Text} from 'grommet';
import chroma from 'chroma-js';
import EventData from "./EventData";


interface Props {
    startDate: Date;
    endDate: Date;
    events: EventData[];
    colorA?: string;
    colorB?: string;
    onClick?: (value: { date: Date }) => void;
}

const CalendarHeatmap: React.FC<Props> = ({
    startDate,
    endDate,
    events,
    colorA = '#b2d8b2',
    colorB = '#008000',
    onClick,
}) => {

    const maxEventCount = Math.max(...events.map(eventData => eventData.count))

    const formatDateKey = (date: Date) => {



        // Ensuring single digit dates and months are padded with 0
        const formattedMonth = ("0" + (date.getMonth() + 1)).slice(-2);
        const formattedDay = ("0" + date.getDate()).slice(-2);

        return `${formattedMonth}/${formattedDay}/${date.getFullYear()}`;
    };

    // Preprocess events data into a dictionary
    const eventDict: {[key: string]: number} = {};

    events.forEach(eventData => {
        const dateKey = formatDateKey(eventData.date);


        if (!eventDict[dateKey]) {
            eventDict[dateKey] = eventData.count;
        } else {
            eventDict[dateKey] += eventData.count;
        }

    });



    const getMonthLabel = (date: Date) => {
        const month = date.toLocaleString("en-US",{ month: 'long'});
        const year = date.getFullYear();

        return `${month} ${year}`;
    };

    const handleDateClick = (date: Date) => {
        if (onClick) {
            onClick({ date });
        }
    };



    const renderMonth = (startDate: Date, endDate: Date) => {
        const monthsToRender = [];
        const currentDate = new Date(startDate.getTime());

        while (currentDate <= endDate) {
            const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
            const lastDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);

            monthsToRender.push(
                <Box key={firstDayOfMonth.toString()} margin={"medium"}>
                    <Text>{getMonthLabel(firstDayOfMonth)}</Text>
                    <Grid columns={['flex', 'flex', 'flex', 'flex', 'flex', 'flex', 'flex']}>
                        {renderMonthGrid(firstDayOfMonth, lastDayOfMonth)}
                    </Grid>
                </Box>
            );

            currentDate.setDate(1); // Set day to 1 to avoid rollover to next month
            currentDate.setMonth(currentDate.getMonth() + 1);
        }

        return monthsToRender;
    };

    const renderMonthGrid = (startMonthDate: Date, endMonthDate: Date) => {
        const monthStartDay = 1;
        const monthEndDay = endMonthDate.getDate();
        const daysBeforeMonth = startMonthDate.getDay(); // 0 for Sunday, 1 for Monday, etc.

        const grid = [];

        // Render white squares before the first day of the month
        for (let i = 0; i < daysBeforeMonth; i++) {
            grid.push(<Box background="white" key={`empty-${i}`} />);
        }

        // Render the days of the month
        for (let day = monthStartDay; day <= monthEndDay; day++) {
            // Calculate the current day
            const currentDay = new Date(startMonthDate.getFullYear(), startMonthDate.getMonth(), day);

            // Check if current day is within the specified date range
            if (currentDay < startDate || currentDay > endDate) {
                // If current day is outside of date range, render white box
                grid.push(
                    <Box width="16px" height="16px" pad="medium" justify="center" align="center">
                        <Text textAlign="center">{day}</Text>
                    </Box>
                );
                continue;
            }

            // Calculate the gradient based on the event count
            const eventCount = eventDict[formatDateKey(currentDay)] || 0;

            let scale = chroma.scale([colorA, colorB]);

            let ratio = eventCount / maxEventCount;

            let blendedColor = scale(ratio).hex();

            const squareStyle = {
                background: eventCount > 0 ? blendedColor : colorA,
            };

            grid.push(
                <Box
                    background={squareStyle.background}
                    key={`day-${day}`}
                    onClick={() => handleDateClick(currentDay)}
                    style={squareStyle}
                    focusIndicator={false}
                    hoverIndicator={true}
                >
                    <Box width="16px" height="16px" pad="medium" justify="center" align="center">
                        <Text textAlign="center">{day}</Text>
                    </Box>
                </Box>
            );
        }

        // Render white squares until the end of the week
        while (grid.length % 7 !== 0) {
            grid.push(<Box background="white" key={`empty-${grid.length}`} />);
        }

        return grid;
    };




    return (



        <Box justify="center" pad="large" direction={"row"} fill={'horizontal'} wrap={true}>
            {renderMonth(startDate, endDate)}
        </Box>
    );
};

export default CalendarHeatmap;

import React, { useState } from 'react';
import { Box, Button, Text } from 'grommet';
import { Checkmark, Close, Trash } from 'grommet-icons';
import { FoodCheckIn } from "../../types/CheckInEvent";

interface FoodCheckInItemProps {
    event: FoodCheckIn;
    onDelete: (event: FoodCheckIn) => void;
}

const FoodCheckInItem: React.FC<FoodCheckInItemProps> = ({ event, onDelete }) => {
    const [selectedToDelete, setSelectedToDelete] = useState(false);

    const handleDelete = () => {
        setSelectedToDelete(true);
    };

    const handleConfirmDelete = () => {
        if (selectedToDelete) {
            onDelete(event);
            setSelectedToDelete(false);
        }
    };

    const handleCancelDelete = () => {
        setSelectedToDelete(false);
    };

    return (
        <Box direction="row" border="bottom" pad="small" width={{min:"400px"}}>
            <Box direction="column" justify="between"  fill={"horizontal"}>
                <Text>{event.guest.UserName}</Text>
                <Text>Phone Number: {event.phoneNumber}</Text>
            </Box>
            <Box
                align="end"
                justify={"center"}
                width={"125px"}
                height={{min:"65px"}}
            >
                {selectedToDelete ? (
                    <Box direction="row">
                        <Button
                            onClick={handleConfirmDelete}
                            icon={<Box pad="xsmall" round="full" background="status-ok"><Checkmark /></Box>}
                        />
                        <Button
                            onClick={handleCancelDelete}
                            icon={<Box pad="xsmall" round="full" background="status-critical"><Close /></Box>}
                        />
                    </Box>
                ) : (
                    <Button icon={<Trash />} onClick={() => handleDelete()} />
                )}
            </Box>
        </Box>
    );
};

export default FoodCheckInItem;

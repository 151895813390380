import {Box, DataTable, Heading} from "grommet";
import React from 'react';
import {ClothingType, ClothingTypeRestrictions, Restriction} from "../../types/ClothingType";
import {ClothingCheckIn} from "../../types/CheckInEvent";
import CheckinEventHandler from "./CheckinEventHandler";
import {EventType} from "../../types/EventType";



const ClothingDataSummary: React.FC = () => {

    let filteredData = CheckinEventHandler.getInstance().getEvents(EventType.CLOTHING) as ClothingCheckIn[] || [];

    const getRestrictionPhrase = (restriction: Restriction): string => {
        const { maxTimes, periodInMonths } = restriction;
        switch (periodInMonths) {
            case 1: return maxTimes === 1 ? 'Once per month' : 'Twice per month';
            case 2: return 'One per two months';
            case 3: return 'One per three months';
            case 6: return 'One per six months';
            case 12: return 'One per year';
            default: return '';
        }
    };

    // Compute the count for each clothing category
    const categoryCount: Record<string, number> = Object.fromEntries(
        Object.values(ClothingType).map(category => [category, 0])
    );

    filteredData.forEach(item => {
        const category = item.category;
        if (!category) return;

        if (!categoryCount[category]) {
            categoryCount[category] = 0;
        }
        categoryCount[category]++;
    });

    // Build the processed data array
    const processedData = Object.keys(categoryCount).map(type => {
        const clothingType = type as ClothingType;
        const count = categoryCount[clothingType];
        const restriction = ClothingTypeRestrictions[clothingType];
        const restrictionPhrase = getRestrictionPhrase(restriction);
        return { ClothingCategory: clothingType, Count: count, Restriction: restrictionPhrase };
    });

    const columns = [
        { property: 'ClothingCategory', header: 'Clothing Category' },
        { property: 'Count', header: 'Count' },
        { property: 'Restriction', header: 'Restriction' },
    ];

    return (
        <Box style={{flexShrink: 0}}>
            <Heading level={2}>Clothing Data Summary</Heading>
            <DataTable
                columns={columns}
                data={processedData}
                paginate={true}
            />
        </Box>
    );
}

export default ClothingDataSummary;

import React from 'react';

import Chart from "react-apexcharts";
import {Box} from "grommet";
import CheckinEventHandler from "../CheckinEventHandler";



const DayDistributionChart: React.FC = () => {

    const series = [{name: "Checkins", data: CheckinEventHandler.getInstance().getDayData()}];

    const options = {
        chart: {
            type: 'bar',
            height: "100%"
        },
        yaxis: {
            title: {
                text: 'Number of People',
            }
        },
        title: {
            text: "Count of Checkins by Day",
            align: 'left',
        },
        legend: {
            position: 'bottom'
        }
    };

    return (
        <Box
            fill
            pad={"medium"}
        >
            <Chart
                options={options}
                series={series}
                type="bar"
                width="100%"
                height="100%"
            />
        </Box>

    );
};

export default DayDistributionChart;

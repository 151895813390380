import React, { useState } from "react";
import {
    Box,
    Button,
    Heading,
    Layer,
    TextInput,
    FormField,
    Form
} from "grommet";
import {Close, CloudUpload} from "grommet-icons";
import { ShowerEvent } from "../../types/CalendarEvent";
import {useMutation, useQuery, useQueryClient} from "react-query";
import CalenderEntry from "../CalenderEntry/CalenderEntry";
import {getShowerEvents, putShowerEvent} from "../../Api/ShowerApi";

const Shower: React.FC = () => {
    const [selectedDate, setSelectedDate] = useState<Date>();
    const [femaleShowered, setFemaleShowered] = useState("");
    const [maleShowered, setMaleShowered] = useState("");
    const [isFutureDate, setIsFutureDate] = useState(false);
    const [fromDate, setFromDate] = useState<Date>(new Date(new Date().getFullYear(), new Date().getMonth() - 3, 1)); // last three months
    const [toDate, setToDate] = useState<Date>(new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)); // end of this month
    const [showLayer, setShowLayer] = useState(false);

    const handleDateClick = (date: Date) => {
        setSelectedDate(date);
        setIsFutureDate(date > new Date());
        setShowLayer(true);
    };

    const queryClient = useQueryClient();

    const { data: events} = useQuery<Record<string, ShowerEvent>, Error>(
        ['fetchShowerEvents', fromDate, toDate],
        () => fetchEvents(fromDate, toDate),
        {
            staleTime: 1000 * 60 * 10, // ten minutes
            cacheTime: 1000 * 60 * 10, // ten minutes
        }
    );

    const eventsForSelectedDate = () => {
        if (selectedDate && events) {
            const dateID = ("0" + (selectedDate.getMonth() + 1)).slice(-2) + '-' + ("0" + selectedDate.getDate()).slice(-2) + '-' + selectedDate.getFullYear();
            return events[dateID] ? [events[dateID]] : [];
        }
        return [];
    };

    const closeLayer = () => {
        setShowLayer(false);
        setSelectedDate(undefined);

        setFemaleShowered("");
        setMaleShowered("");
    };

    const fetchEvents = async (fromDate: Date, toDate: Date): Promise<Record<string, ShowerEvent>> => {
        const eventsArray = await getShowerEvents(fromDate, toDate);
        return eventsArray.reduce((acc: Record<string, ShowerEvent>, event) => {
            acc[event.DateID] = event;
            return acc;
        }, {});
    };


    const addEventMutation = useMutation(putShowerEvent, {
        onSuccess: () => {
            queryClient.invalidateQueries('fetchShowerEvents');
        },
    });

    const addEvent = () => {

        if (selectedDate && (femaleShowered || maleShowered)) {
            const dateID = ("0" + (selectedDate.getMonth() + 1)).slice(-2) + '-' + ("0" + selectedDate.getDate()).slice(-2) + '-' + selectedDate.getFullYear();

            let newEvent: ShowerEvent;



            // If the event already exists, update it.
            if (events && events[dateID]) {
                newEvent = { ...events[dateID] };
                if (femaleShowered) {
                    newEvent.FemaleShowered = Number(femaleShowered);
                }
                if (maleShowered) {
                    newEvent.MaleShowered = Number(maleShowered);
                }
            } else {
                // Otherwise, create a new event.
                newEvent = {
                    DateID: dateID,
                    Date: new Date(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate(), 12, 0, 0, 0),
                    FemaleShowered: femaleShowered ? Number(femaleShowered) : 0,
                    MaleShowered: maleShowered ? Number(maleShowered) : 0,
                };
            }

            addEventMutation.mutate(newEvent);

            if (femaleShowered) {
                setFemaleShowered("");
            }
            if (maleShowered) {
                setMaleShowered("");
            }
        }
    };

    function getCurrentTimeZoneOffset(): string {
        // Create a new Date object
        const date = new Date();

        // Get the timezone offset in minutes
        let offsetInMinutes = -date.getTimezoneOffset();

        // Determine the sign
        const sign = offsetInMinutes >= 0 ? '+' : '-';

        // Convert offset to hours and minutes
        const hours = Math.floor(Math.abs(offsetInMinutes) / 60);
        const minutes = Math.abs(offsetInMinutes) % 60;

        // Format hours and minutes to two digits
        const formattedHours = String(hours).padStart(2, '0');
        const formattedMinutes = String(minutes).padStart(2, '0');

        // Return the formatted time zone offset
        return `${sign}${formattedHours}:${formattedMinutes}`;
    }


    return (
        <Box align="center" pad={'medium'} style={{flexShrink: 0}} overflow={'auto'} fill>



            <Heading level={3}>Shower</Heading>

            <CalenderEntry
                fromDate={fromDate}
                toDate={toDate}
                setFromDate={setFromDate}
                setToDate={setToDate}
                events={Object.entries(events || {}).map(([dateString, kitchenEvent]) => {
                    // Assuming kitchenEvent has a property or a method to get the count
                    const count = kitchenEvent.MaleShowered + kitchenEvent.FemaleShowered; // Replace with actual logic to get count
                    const [month, day, year] = dateString.split("-");
                    dateString = `${year}-${month}-${day}T12:00:00${getCurrentTimeZoneOffset()}`;

                    return {
                        date: new Date(dateString),
                        count: count
                    }})}
                onDateClick={handleDateClick}
            />

            {showLayer && selectedDate && (
                <Layer onEsc={closeLayer} onClickOutside={closeLayer} position="center">
                    <Box pad="medium" width={"medium"}>
                        <Box direction="row" justify="between">
                            <Heading level={4}>{selectedDate.toLocaleDateString()}</Heading>
                            <Button icon={<Close />} onClick={closeLayer} />
                        </Box>
                        <Box align={"center"}>
                            <Heading level={2}>
                                Total: {eventsForSelectedDate().length > 0 ? eventsForSelectedDate()[0].MaleShowered + eventsForSelectedDate()[0].FemaleShowered : "0"}
                            </Heading>
                        </Box>
                        <Box direction={"row"} justify={"start"}>
                            <Box fill pad={{start:"small"}}>
                                <Heading level={3}>
                                    Female: {eventsForSelectedDate().length > 0 ? eventsForSelectedDate()[0].FemaleShowered : "0"}
                                </Heading>
                            </Box>
                            <Box fill pad={{start:"small"}}>
                                <Heading level={3}>
                                    Male: {eventsForSelectedDate().length > 0 ? eventsForSelectedDate()[0].MaleShowered : "0"}
                                </Heading>
                            </Box>
                        </Box>

                        <Form onSubmit={addEvent}>
                            <Box direction="row" justify={"between"} align={"top"} pad={{vertical:"medium"}} gap={"small"}>
                                <FormField name={"femaleShowered"} validate={() => Number(femaleShowered) < 0 ? "No Negative": undefined}>
                                    <TextInput
                                        value={femaleShowered}
                                        type={'number'}
                                        placeholder="Female"
                                        onChange={(event) => setFemaleShowered(event.target.value)}
                                    />
                                </FormField>
                                <FormField name={"peopleServedPM"} validate={() => Number(maleShowered) < 0 ? "No Negative": undefined}>
                                    <TextInput
                                        value={maleShowered}
                                        type={'number'}
                                        placeholder="Male"
                                        onChange={(event) => setMaleShowered(event.target.value)}
                                    />
                                </FormField>
                            </Box>

                            <Button
                                icon={<CloudUpload/>}
                                label="Update"
                                primary
                                fill = {"horizontal"}
                                type={"submit"}
                                reverse={true}
                                disabled={isFutureDate}
                                title={isFutureDate ? "Cannot update future dates" : ""}
                            />
                        </Form>


                    </Box>
                </Layer>
            )}
        </Box>
    );
};

export default Shower;

import {User} from "../types/User";
import {CheckinEvent} from "../types/CheckInEvent";
import {Auth} from 'aws-amplify';
import {HourLengthOption, MinuteLengthOption} from "../types/VolunteerTimeTypes";

const checkinRateLimiter: Record<string, { guestId: string, timestamp: Date }> = {};

function getIdToken() {
    return Auth.currentSession().then(session => session.getIdToken().getJwtToken());
}

export const getUsers = async (name: string, birthday: Date | undefined): Promise<User[]> => {

    let birthdayUnix = ""
    if (birthday !== undefined){
        birthdayUnix = Math.floor(birthday.getTime() / 1000).toString();
    }

    const url = `${process.env.REACT_APP_API_ENDPOINT}/api/guest?name=${encodeURIComponent(name)}&birthday=${birthdayUnix}`;

    // Fetch data from the API
    const response = await fetch(url, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${await getIdToken()}`,
        },
    });


    if (!response.ok) {
        return [];
    }

    // Parse the response to JSON
    const data = await response.json();

    if(data === null) {
        return [];
    }

    const users: User[] = [];
    // Assuming the response is an array of users, map it to your users array
    for (let user of data) {
        users.push({
            GuestID: user.GuestID,
            UserName: user.UserName,
            // Convert UNIX timestamp to Date object
            Birthday: new Date(user.Birthday * 1000),
            Gender: user.Gender,
            Note: user.Note,
        });


    }

    return users;
};

export const postGuest = async (name: string, birthday: Date, gender: string) => {

    const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/api/guest`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${await getIdToken()}`,
        },
        body: JSON.stringify({
            UserName: name,
            Birthday: Math.floor(birthday.getTime() / 1000),
            Gender: gender.charAt(0).toUpperCase() + gender.slice(1),
        }),
    });

    if (!response.ok) {
        const responseBody = await response.json();
        throw new Error(responseBody.error || `HTTP error! status: ${response.status}`);
    }

    const responseBody = await response.json();
    return responseBody.GuestID;
};

export const checkinGuest = async (guestId: string, serviceId: string, checkinId: string) => {

    const session = await Auth.currentSession();
    const idToken = session.getIdToken().getJwtToken();

    // Retrieve the current record for the service if it exists
    const currentRecord = checkinRateLimiter[serviceId];

    // Check if the same guest is trying to check in within one minute
    if (currentRecord && currentRecord.guestId === guestId) {
        const timeDifference = (new Date()).getTime() - currentRecord.timestamp.getTime();
        if (timeDifference < 60000) {
            checkinRateLimiter[serviceId] = { guestId, timestamp: new Date() };
            return {};
        }
    }

    // Update the record with the new guest and timestamp
    checkinRateLimiter[serviceId] = { guestId, timestamp: new Date() };

    const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/api/checkin/${checkinId}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${idToken}`,
        },
        body: JSON.stringify({
            GuestID: guestId,
            ServiceID: serviceId,
        }),
    });

    return await response.json();
};

export const deleteCheckin = async (checkinEvent: CheckinEvent) => {
    const session = await Auth.currentSession();
    const idToken = session.getIdToken().getJwtToken();

    const url = new URL(`${process.env.REACT_APP_API_ENDPOINT}/api/checkin/${checkinEvent.CheckInID}`);

    const response = await fetch(url, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${idToken}`,
        },
    });

    return await response.json();
};

export const patchGuestNote = async (user: User) => {
    const session = await Auth.currentSession();
    const idToken = session.getIdToken().getJwtToken();

    const requestOptions = {
        method: "PATCH",
        headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${idToken}`,
        },
        body: JSON.stringify({ Note:user.Note }),
    };

    const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/api/guest/${user.GuestID}/note`, requestOptions);
    return await response.json();

};

export const patchGuestSex = async (user: User) => {
    const session = await Auth.currentSession();
    const idToken = session.getIdToken().getJwtToken();

    const requestOptions = {
        method: "PATCH",
        headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${idToken}`,
        },
        body: JSON.stringify({ Sex:user.Gender }),
    };

    const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/api/guest/${user.GuestID}/sex`, requestOptions);
    return await response.json();

};

export const getCheckIns = async (startDay: Date, endDay: Date, serviceID="", guestID =""): Promise<CheckinEvent[]> => {

    // Convert dates to Unix timestamps
    const startDayUnix = Math.floor(startDay.getTime() / 1000);
    const endDayUnix = Math.floor(endDay.getTime() / 1000);

    // Create URL with query parameters
    const url = new URL(`${process.env.REACT_APP_API_ENDPOINT}/api/checkin`);
    url.searchParams.append('service-id', serviceID);
    url.searchParams.append('guest-id', guestID);
    url.searchParams.append('start-date', startDayUnix.toString());
    url.searchParams.append('end-date', endDayUnix.toString());

    const requestOptions = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${await getIdToken()}`,
        },
    };

    try {
        const response = await fetch(url.toString(), requestOptions);
        const data = await response.json();

        //no one has checked in yet
        if (!data.Guests) {
            return [];
        }

        const processedGuests = data.Guests.map((user: any) => {
            return {
                GuestID: user.GuestID,
                UserName: user.UserName,
                Birthday: new Date(user.Birthday * 1000), // convert Unix timestamp to Date
                Gender: user.Gender,
                Note: user.Note,
            };
        });

        // Create a map of users for quick lookup by ID
        const userMap = new Map(processedGuests.map((user: User) => [user.GuestID, user]));

        // Convert check-ins to CheckinEvent format and replace guest ID with guest object
        return data.CheckIns.map((checkIn: any) => ({
            area: checkIn.Area,
            email: checkIn.Email,
            phone: checkIn.Phone,
            organization: checkIn.Organization,
            checkedOut: checkIn.CheckedOut,
            familySize: checkIn.FamilySize,
            homelessStatus: checkIn.HomelessStatus,
            foodBoxDesignation: checkIn.FoodBoxDesignation,
            className: checkIn.ClassID,
            CheckInID: checkIn.CheckInID,
            serviceID: checkIn.ServiceID,
            date: new Date(checkIn.Date * 1000), // convert Unix timestamp to Date
            hours: checkIn.Hours as HourLengthOption,
            minutes: checkIn.Minutes as MinuteLengthOption,
            guest: userMap.get(checkIn.GuestID) || null, // replace GuestID with guest object
            hygieneItems: checkIn.HygieneItems,
            phoneNumber: checkIn.PhoneNumber
        }));

    } catch (error) {
        console.error("Error during API call:", error);
        return [];
    }
};






import {Auth} from "aws-amplify";
import {ClassroomClass} from "../types/ClassType";
import {ClassroomCheckIn} from "../types/CheckInEvent";
import {getCheckIns} from "./api";
import {EventServiceId, EventType} from "../types/EventType";

export const postClass = async (classEvent: ClassroomClass
) => {
    const session = await Auth.currentSession();
    const idToken = session.getIdToken().getJwtToken();

    // Convert Dates into Unix timestamp (seconds since 1970)
    const datesUnix = classEvent.dates.map(date => Math.floor(date.getTime() / 1000));

    const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/api/classes`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${idToken}`,
        },

        body: JSON.stringify({
            ClassID: classEvent.name,
            Dates: datesUnix,
            Description: classEvent.description,
            Length: classEvent.length,
        }),
    });

    if (!response.ok) {
        const responseBody = await response.json();
        throw new Error(responseBody.error || `HTTP error! status: ${response.status}`);
    }

    return response.json();
};


export const getClass = async (): Promise<ClassroomClass[]> => {
    const session = await Auth.currentSession();
    const idToken = session.getIdToken().getJwtToken();



    const requestOptions = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${idToken}`,
        },
    };

    const url = new URL(`${process.env.REACT_APP_API_ENDPOINT}/api/classes`);

    try {
        const response = await fetch(url, requestOptions);
        const rawData = await response.json();

        // assuming the raw data matches the ClassEvent interface
        return rawData.map((event: any) => ({
            name: event.ClassID,
            dates: event.Dates.map((date: number) => new Date(date * 1000)),
            description: event.Description,
            length: event.Length,
        }));

    } catch (error) {
        console.error("Error during API call:", error);
        return [];
    }
};

export const postClassCheckIn = async (classCheckInEvent: ClassroomCheckIn) => {
    const session = await Auth.currentSession();
    const idToken = session.getIdToken().getJwtToken();

    const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/api/checkin/${classCheckInEvent.CheckInID}/classroom`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${idToken}`,
        },
        body: JSON.stringify({
            Date: Math.floor(classCheckInEvent.date.getTime() / 1000),
            ClassID: classCheckInEvent.className,
            GuestID: classCheckInEvent.guest.GuestID,
        }),
    });

    return await response.json();
}

export const getClassCheckIn = async (startDate: Date, endDate: Date): Promise<ClassroomCheckIn[]> => {
    const checkIns = await getCheckIns(startDate, endDate, EventServiceId[EventType.CLASSROOM]);
    return checkIns.map(item => item as ClassroomCheckIn);
};

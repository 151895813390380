// src/common/AddUserModal.tsx
import React, { useEffect, useState } from "react";
import { Box, Button, DateInput, FormField, Layer, Select, TextInput, Form, Text} from "grommet";
import { Genders } from "../../types/Genders";
import {postGuest} from "../../Api/api";
import {dataFormat} from "../../types/Constants";

interface AddUserModalProps {
    open: boolean;
    onClose: (name?: string, birthday?: Date) => void;
    initialName?: string;
    initialBirthday?: Date;
}

const AddUserModal: React.FC<AddUserModalProps> = ({
    open,
    onClose,
    initialName = "",
    initialBirthday,
}) => {
    const [firstName, setFirstName] = useState("");
    const [middleName, setMiddleName] = useState("");
    const [lastName, setLastName] = useState("");

    const [birthday, setBirthday] = useState("");
    const [gender, setGender] = useState("");
    const [errorMessage, setErrorMessage] = useState<string>("");

    useEffect(() => {
        // Split the initialName by space
        const spaceSeparatedParts = initialName.split(' ');

        let nameParts: string[] = [];

        // Loop over the space-separated parts and split each by hyphen
        spaceSeparatedParts.forEach(part => {
            nameParts = [...nameParts, ...part.split('-')];
        });

        // Assign the parts to the correct state variables based on the length of the array
        switch(nameParts.length) {
            case 3:
                setLastName(nameParts[2]);
                setMiddleName(nameParts[1]);
                setFirstName(nameParts[0]);
                break;
            case 2:
                setLastName(nameParts[1]);
                setFirstName(nameParts[0]);
                break;
            case 1:
                setFirstName(nameParts[0]);
                setMiddleName("");
                setLastName("");
                break;
            case 0:
                setFirstName("");
                setMiddleName("");
                setLastName("");
                break;
            default:
                setLastName(nameParts.splice(2).join(' '));
                setMiddleName(nameParts[1]);
                setFirstName(nameParts[0]);
        }



        setBirthday(initialBirthday ? initialBirthday.toISOString().split("T")[0] : "");
    }, [initialName, initialBirthday]);


    if (!open) return null;


    function parseName(): string {
        const nameParts = [lastName, middleName, firstName];

        const formattedParts = nameParts
            .filter(Boolean)  // Remove any undefined or empty parts
            .map(name => name.toLowerCase())  // set all to lower case
            .map(name => name.trim())  // Remove leading and trailing spaces
            .map(name => name.replace(/\b\w/g, match => match.toUpperCase()));

        return formattedParts.join(' ');  // Join the names with a space
    }

    const validateDate = (dateStr: string) => {
        if (!dateStr) return " Please use m/d/yyyy.";
        let date = new Date(dateStr);
        if (isNaN(date.getTime())) return "Date format is incorrect. Please use m/d/yyyy.";
        if (date > new Date()) return "Date cannot be in the future.";
        return undefined;
    };

    const validateRequiredName = (nameStr: string) => {
        if (!nameStr) return "Required";
        nameStr = nameStr.trim();
        const isAlphabetic = /^[a-zA-Z- ]+$/.test(nameStr);
        if (!isAlphabetic) return "Only alphabetic characters";
        const dashSpaceTest = /^[a-zA-Z](?:[ -]?[a-zA-Z])*$/.test(nameStr);
        if (!dashSpaceTest) return "Single dashes or spaces must be between letters";

        return undefined;
    };

    const validateName = (nameStr: string) => {
        if (!nameStr) return undefined;
        nameStr = nameStr.trim();
        const isAlphabetic = /^[a-zA-Z- ]+$/.test(nameStr);
        if (!isAlphabetic) return "Only alphabetic characters";
        const dashSpaceTest = /^[a-zA-Z](?:[ -]?[a-zA-Z])*$/.test(nameStr);
        if (!dashSpaceTest) return "Single dashes or spaces must be between letters";

        return undefined;
    };

    const handleSubmit = async () => {
        if (!birthday) {
            return
        }

        try {

            let parts = birthday.slice(0,10).split('-');
            let day = Number(parts[2]);
            let month = Number(parts[1]) - 1; // months are 0-based in JavaScript
            let year = Number(parts[0]);

            // Create a Date object for noon UTC on the birthday
            let date = new Date(year, month, day, 12, 0, 0);

            await postGuest(parseName(), date, gender);

            setFirstName("");
            setMiddleName("");
            setLastName("");

            setBirthday("");
            setGender("");

            onClose(parseName(), date);

        } catch (error) {
            // @ts-ignore
            console.error('Error:', error.message);
            // @ts-ignore
            if (error.message === "Duplicate Guest") { // check for the error message here
                setErrorMessage("A guest with the same name and similar birthday already exists.");
            }
        }
    };

    return (
        <Layer onEsc={() => onClose()} onClickOutside={() => onClose()}>
            <Form onSubmit={handleSubmit}>
                <Box pad="medium" width="medium">
                    <FormField label="First Name*" name="FirstName" validate={validateRequiredName}>
                        <TextInput name="FirstName" value={firstName} onChange={(event) => setFirstName(event.target.value)} />
                    </FormField>
                    <FormField label="Middle Name" name="MiddleName" validate={validateName}>
                        <TextInput name="MiddleName" value={middleName} onChange={(event) => setMiddleName(event.target.value)} />
                    </FormField>
                    <FormField label="Last Name*" name="LastName" validate={validateRequiredName}>
                        <TextInput name="LastName" value={lastName} onChange={(event) => setLastName(event.target.value)} />
                    </FormField>
                    <FormField label="Birthday*" name="birthday" validate={validateDate}>
                        <DateInput
                            format={dataFormat}
                            name="birthday"
                            value={birthday}
                            onChange={(event) => setBirthday(event.value as string)}
                            autoComplete="off"
                        />
                    </FormField>
                    <FormField label="Gender" name="gender" required>
                        <Select
                            name="gender"
                            value={gender}
                            options={Object.values(Genders)}
                            onChange={({ option }) => setGender(option)}
                        />
                    </FormField>
                    {errorMessage && (
                        <Text color="status-error">
                            {errorMessage}
                        </Text>
                    )}
                    <Box direction="row" justify="between" margin={{ top: "medium" }}>
                        <Button label="Cancel" onClick={() => onClose()} />
                        <Button primary type={"submit"} label="Add User"/>
                    </Box>
                </Box>
            </Form>
        </Layer>
    );
};

export default AddUserModal;

import React from 'react';

import Chart from "react-apexcharts";
import {Box} from "grommet";
import CheckinEventHandler from "../CheckinEventHandler";


const PieChartComponent: React.FC = () => {

    const series = CheckinEventHandler.getInstance().getGenderData();

    const options = {
        chart: {
            type: 'pie',
            height: "100%"
        },
        labels: ["Male", "Female"],
        title: {
            text: "Sex Ratio",
            align: 'left'
        },
        legend: {
            position: 'bottom'
        }
    };

    return(
        <Box
            fill
            pad={"medium"}
        >
            <Chart
                options={options}
                series={series}
                type="pie"
                width="100%"
                height="100%"
            />
        </Box>

    );
};

export default PieChartComponent;

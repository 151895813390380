import React from 'react';
import {Box, Heading, Spinner, Text} from 'grommet';
import { FoodCheckIn } from "../../types/CheckInEvent";
import {User} from "../../types/User";
import {getFoodCheckIn} from "../../Api/foodPantryApi";
import { useQuery } from 'react-query';

interface GuestFoodDetailsItemProps {
    user: User;
    selected: boolean;
}


const GuestFoodDetailsItem: React.FC<GuestFoodDetailsItemProps> = ({ user, selected }) => {

    const startDay = new Date();
    startDay.setHours(0, 0, 0, 0);
    startDay.setMonth(startDay.getMonth() - 6);

    // Get the end of the current day
    const endDay = new Date();
    endDay.setHours(23, 59, 59, 999);

    const fetchFoodCheckIn = async (): Promise<FoodCheckIn[]> => {
        return await getFoodCheckIn(startDay, endDay, user.GuestID);
    }

    const { isLoading, isError, data: foodCheckInEvents } =
        useQuery<FoodCheckIn[], Error>(`${user.GuestID}-Food`, fetchFoodCheckIn, {
        staleTime: 1000 * 60 * 10, // ten minutes
        cacheTime: 1000*60*10, //ten minutes
    });

    if (isLoading) {
        return  (
            <Box background={selected ? 'brand' : undefined} border={!selected} pad="medium" margin={{vertical:"small"}} style={{flexShrink: 0}}>
                <Heading margin={{top:"small", bottom:"medium"}} level={"2"}>{user.UserName}</Heading>
                <Box direction="row" wrap={true} align="center" justify={"center"}>
                    <Spinner size={"large"}/>
                </Box>
            </Box>
        )
    }

    if (isError) {
        return  (
            <Box background={selected ? 'brand' : undefined} border={!selected} pad="medium" margin={{vertical:"small"}} style={{flexShrink: 0}}>
                <Heading margin={{top:"small", bottom:"medium"}} level={"2"}>{user.UserName}</Heading>
                <Box direction="row" wrap={true} align="center" justify={"center"}>
                    <Heading level={"3"}>Error</Heading>
                </Box>
            </Box>
        )
    }

    if (foodCheckInEvents?.length === 0) {
        return (<></>)
    }

    return (
        <Box background={selected ? 'brand' : undefined} border={!selected} pad="medium" margin={{vertical:"small"}} style={{flexShrink: 0}}>
            <Heading margin={{top:"small", bottom:"medium"}} level={"2"}>{user.UserName}</Heading>
            <Box direction="row" wrap={true} align="center" justify={"center"}>
                {foodCheckInEvents && foodCheckInEvents.length > 0 && (
                    foodCheckInEvents.map((event: FoodCheckIn, index: number) => (
                        <Box key={index} border={true} width="275px" pad="small" margin={"small"}>
                            <Text size="small">{`Phone Number: ${event.phoneNumber}`}</Text>
                            <Text size="small">{`Homeless Status: ${event.homelessStatus}`}</Text>
                            <Text size="small">{`Food Box: ${event.foodBoxDesignation}`}</Text>
                            <Text size="small">{`Date: ${event.date.toLocaleDateString()}`}</Text>
                        </Box>
                    ))
                )}

            </Box>
        </Box>
    );

};

export default GuestFoodDetailsItem;


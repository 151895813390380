import { FC, ReactNode } from 'react';
import { Box } from 'grommet';

interface GraphWrapperProps {
    children: ReactNode;
    width: { min: string, width: string, max: string };
    height: { min: string, height: string, max: string };
}

const GraphWrapper: FC<GraphWrapperProps> = ({ children, width, height }) => {
    return (
        <Box align="center" justify="center" width={width} height={height} style={{flexShrink: 0}}>
            {children}
        </Box>
    );
};

export default GraphWrapper;

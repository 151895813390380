import React, {useEffect, useState} from 'react';
import {EventType} from "../../types/EventType";
import {Box} from "grommet";
import PieChartComponent from "./Graphs/PieChartComponent";
import DayDistributionChart from "./Graphs/DayDistributionChart";
import HistogramComponent from "./Graphs/HistogramComponent";
import DataTableSummary from "./DataSummary";
import GraphWrapper from "./Graphs/GraphWrapper";
import {KitchenCountsPlot} from "./Graphs/KitchenCountsPlot";

import KitchenDataSummary from "./KitchenDataSummary";
import ClothingDataSummary from "./ClothingDataSummary";
import ShowerDataSummary from "./ShowerDataSummary";
import {ShowerCountsPlot} from "./Graphs/ShowerCountsPlot";
import CheckinEventHandler from "./CheckinEventHandler";

type DataRendererProps = {
    selectedService: Array<EventType>
}

const DataRenderer: React.FC<DataRendererProps> = ({ selectedService }) => {

    // eslint-disable-next-line
    const [_, setRenderTrigger] = useState(0);

    useEffect(() => {

        const triggerReRender = () => {
            setRenderTrigger(prev => prev + 1);
        };
        const unsubscribe = CheckinEventHandler.getInstance().subscribe(triggerReRender);

        // Cleanup the subscription when the component unmounts
        return () => unsubscribe();
    }, []);

    //if selected service is empty, render No Service Selected
    if (selectedService.length === 0) {
        return (
            <Box pad={{horizontal:"medium"}} direction={"column"} fill>
                <h1>No Service Selected</h1>
            </Box>
        );
    }

    //rename this
    const shouldShowGraphs = selectedService.some(eventType =>
        eventType !== EventType.KITCHEN && eventType !== EventType.SHOWER
    );

    return (
        <Box pad={{horizontal:"medium"}} direction={"column"} fill>

            <h1>{selectedService.join(" ")}</h1>
                <Box direction={"row"} justify={"evenly"} wrap={true} style={{flexShrink: 0}}>
                    {shouldShowGraphs && (<>
                        <GraphWrapper
                            //these are ment to be vw on both, so it's a square
                            width={{min: '300px', width: '30vw', max: '500px'}}
                            height={{min: '300px', height: '30vw', max: '500px'}}
                        >
                            <PieChartComponent/>
                        </GraphWrapper>

                        <GraphWrapper
                            width={{min: '300px', width: '30vw', max: '500px'}}
                            height={{min: '300px', height: '30vw', max: '500px'}}
                        >
                            <DayDistributionChart/>
                        </GraphWrapper>

                        <GraphWrapper
                            width={{min: '300px', width: '30vw', max: '500px'}}
                            height={{min: '300px', height: '30vw', max: '500px'}}
                        >
                            <HistogramComponent/>
                        </GraphWrapper>
                    </>)}


                    {selectedService.includes(EventType.KITCHEN) && (
                        <KitchenCountsPlot/>
                    )}

                    {selectedService.includes(EventType.SHOWER) && (
                        <ShowerCountsPlot/>
                    )}
                </Box>



                <Box direction="column" gap="medium">
                    {selectedService.includes(EventType.CLOTHING) && (
                        <ClothingDataSummary/>
                    )}
                    {selectedService.includes(EventType.KITCHEN) && (
                        <KitchenDataSummary/>
                    )}
                    {selectedService.includes(EventType.SHOWER) && (
                        <ShowerDataSummary/>
                    )}
                    {shouldShowGraphs && (
                        <DataTableSummary/>
                    )}

                </Box>
            <Box
                height={"5vh"}
            />
        </Box >
    );
}

export default DataRenderer;

import {Auth} from "aws-amplify";
import {ShowerEvent} from "../types/CalendarEvent";

export const putShowerEvent = async (
    showerEvent: ShowerEvent
) => {
    const session = await Auth.currentSession();
    const idToken = session.getIdToken().getJwtToken();

    const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/api/shower`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${idToken}`,
        },

        body: JSON.stringify({
            DateID: showerEvent.DateID,
            Date: Math.floor(showerEvent.Date.getTime() / 1000),
            MaleShowered: showerEvent.MaleShowered,
            FemaleShowered: showerEvent.FemaleShowered,
        }),
    });

    if (!response.ok) {
        const responseBody = await response.json();
        throw new Error(responseBody.error || `HTTP error! status: ${response.status}`);
    }

    return response.json();
};


export const getShowerEvents = async (startDate: Date, endDate: Date): Promise<ShowerEvent[]> => {
    const session = await Auth.currentSession();
    const idToken = session.getIdToken().getJwtToken();

    // Convert dates to Unix timestamp (seconds since 1970)
    const startUnix = Math.floor(startDate.getTime() / 1000);
    const endUnix = Math.floor(endDate.getTime() / 1000);

    const requestOptions = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${idToken}`,
        },
    };

    const url = new URL(`${process.env.REACT_APP_API_ENDPOINT}/api/shower`);
    url.searchParams.append('start', startUnix.toString());
    url.searchParams.append('end', endUnix.toString());

    try {
        const response = await fetch(url, requestOptions);
        const rawData = await response.json();

        // the raw data should match the KitchenEvent interface
        return rawData.map((event: any) => ({
               DateID: event.DateID,
                Date: new Date(event.Date * 1000),
                MaleShowered: event.MaleShowered,
                FemaleShowered: event.FemaleShowered,
        }));

    } catch (error) {
        return [];
    }
};


import React, {useEffect} from "react";
import {
    Box,
    DateInput,
    Text,
} from "grommet";
import {Calendar} from "grommet-icons";
import CalendarHeatmap from "../CalenderEntry/CalenderHeatmap";
import EventData from "./EventData";


type CalenderEntryProps = {
    events: EventData[];
    onDateClick: (date: Date) => void;
    fromDate: Date;
    toDate: Date;
    setFromDate: (date: Date) => void;
    setToDate: (date: Date) => void;
};

const CalenderEntry: React.FC<CalenderEntryProps> = ({
    events,
    onDateClick,
    fromDate,
    toDate,
    setFromDate,
    setToDate,
}) => {

    useEffect(() => {
        setFromDate(new Date(new Date().getFullYear(), new Date().getMonth() - 3, 1));
        setToDate(new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0));


        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Box align="center" pad={'medium'} style={{flexShrink: 0}} overflow={'auto'} fill>
            <Box style={{flexShrink: 0}} align="center">
                <Text>Click on a date to see the number served that day</Text>
                <Box direction="row" gap="small">
                    <Box direction="row" gap="small" align={"center"}>
                        <Text>From:</Text>
                        <Text>{fromDate.toLocaleDateString('en-US', {month: '2-digit', day: '2-digit', year: '2-digit' })}</Text>
                        <DateInput
                            buttonProps={{ icon: <Calendar /> }}
                            value={fromDate.toISOString()}
                            onChange={({ value }) => value && typeof value === 'string' && setFromDate(new Date(value))}
                        />
                        <Text>To:</Text>
                        <Text>{toDate.toLocaleDateString('en-US', {month: '2-digit', day: '2-digit', year: '2-digit' })}</Text>
                        <DateInput
                            buttonProps={{ icon: <Calendar /> }}
                            value={toDate.toISOString()}
                            onChange={({ value }) => value && typeof value === 'string' && setToDate(new Date(value))}
                        />
                    </Box>
                </Box>
            </Box>

            <Box style={{flexShrink: 0}}>
                <CalendarHeatmap
                    startDate={fromDate}
                    endDate={toDate}
                    events={events}
                    onClick={(value: { date: Date }) => {
                        onDateClick(value.date);
                    }}
                />
            </Box>
        </Box>
    );
};

export default CalenderEntry;
import {ClothingType} from "../types/ClothingType"
import {Auth} from "aws-amplify";
import {ClothingCheckIn} from "../types/CheckInEvent";
import {User} from "../types/User";
import {EventServiceId, EventType} from "../types/EventType";

export const postGuestClothing = async (clothingCheckIn: ClothingCheckIn) => {
    const session = await Auth.currentSession();
    const idToken = session.getIdToken().getJwtToken();

    const indexOfS = Object.values(ClothingType).indexOf(clothingCheckIn.category as unknown as ClothingType);
    const categoryKey = Object.keys(ClothingType)[indexOfS];

    const requestOptions = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${idToken}`,
        },
        body: JSON.stringify({
            ClothingType: categoryKey,
            GuestID: clothingCheckIn.guest.GuestID,
        }),
    };

    try {
        const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/api/checkin/${clothingCheckIn.CheckInID}/clothing`, requestOptions);
        return await response.json();
    } catch (error) {
        console.error("Error during API call:", error);
    }
};

const getGuestClothingChunk = async (
    startDate: Date,
    endDate: Date,
    guestID: string = 'all'
): Promise<ClothingCheckIn[]> => {
    const session = await Auth.currentSession();
    const idToken = session.getIdToken().getJwtToken();

    // Convert dates to Unix timestamp (seconds since 1970)
    const startUnix = Math.floor(startDate.getTime() / 1000);
    const endUnix = Math.floor(endDate.getTime() / 1000);

    const requestOptions = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${idToken}`,
        },
    };

    const url = new URL(`${process.env.REACT_APP_API_ENDPOINT}/api/guest/${guestID}/clothing`);
    url.searchParams.append('start', startUnix.toString());
    url.searchParams.append('end', endUnix.toString());

    try {
        const response = await fetch(url, requestOptions);
        const data = await response.json();

        //no one has checked in yet
        if (!data.Guests) {
            return [];
        }

        const processedGuests = data.Guests.map((user: any) => {
            return {
                GuestID: user.GuestID,
                UserName: user.UserName,
                Birthday: new Date(user.Birthday * 1000), // convert Unix timestamp to Date
                Gender: user.Gender,
                Note: user.Note,
            };
        });

        // Create a map of users for quick lookup by ID
        const userMap = new Map(processedGuests.map((user: User) => [user.GuestID, user]));

        // Convert the received data to match the ClothingEvent type
        return data.CheckIns.map((event: any) => ({
            guest: userMap.get(event.GuestID),
            CheckInID: event.CheckInID,
            serviceID: EventServiceId[EventType.CLOTHING],
            date: new Date(event.Date * 1000), // Convert Unix timestamp to Date object
            category: ClothingType[event.ClothingItemID as keyof typeof ClothingType],
        }));
    } catch (error) {
        console.error("Error during API call:", error);
        return [];
    }
};

const chunkByMonths = (start: Date, end: Date): [Date, Date][] => {
    let monthlyChunks: [Date, Date][] = [];
    let startYear = start.getFullYear();
    let startMonth = start.getMonth();
    let endYear = end.getFullYear();
    let endMonth = end.getMonth();

    // Calculate total months difference
    let totalMonths = (endYear - startYear) * 12 + (endMonth - startMonth);

    for (let i = 0; i <= totalMonths; i++) {
        let chunkStartYear = startYear + Math.floor((startMonth + i) / 12);
        let chunkStartMonth = (startMonth + i) % 12;
        let chunkStart: Date;
        let chunkEnd: Date;

        if (i === 0) {
            // For the first chunk, start from the given start date
            chunkStart = new Date(start);
        } else {
            // Otherwise, start from the first of each month
            chunkStart = new Date(chunkStartYear, chunkStartMonth, 1);
        }

        if (i === totalMonths) {
            // For the last chunk, end on the given end date
            chunkEnd = new Date(end);
        } else {
            // Otherwise, end just before the first of the next month
            let nextMonthYear = chunkStartYear + Math.floor((chunkStartMonth + 1) / 12);
            let nextMonth = (chunkStartMonth + 1) % 12;
            chunkEnd = new Date(nextMonthYear, nextMonth, 1);
        }

        monthlyChunks.push([chunkStart, chunkEnd]);
    }

    return monthlyChunks;
};


const chunkByYears = (start: Date, end: Date): [Date, Date][] => {
    let yearlyChunks: [Date, Date][] = [];
    let startYear = start.getFullYear();
    let endYear = end.getFullYear();

    for (let year = startYear; year <= endYear; year++) {
        let chunkStart: Date;
        let chunkEnd: Date;

        if (year === startYear) {
            // For the first chunk, start from the given start date
            chunkStart = new Date(start);
        } else {
            // For subsequent years, start from January 1st of that year
            chunkStart = new Date(year, 0, 1);
        }

        if (year === endYear) {
            // For the last chunk, end on the given end date
            chunkEnd = new Date(end);
        } else {
            // For other years, end just before January 1st of the next year
            chunkEnd = new Date(year + 1, 0, 1);
        }

        // Ensure the chunkEnd is not after the end date
        if (chunkEnd > end) {
            chunkEnd = new Date(end);
        }

        yearlyChunks.push([chunkStart, chunkEnd]);
    }

    return yearlyChunks;
};



export const getGuestClothing = async (
    startDate: Date,
    endDate: Date,
    guestID?: string
): Promise<ClothingCheckIn[]> => {
    let chunks: [Date, Date][];
    if (guestID) {
        chunks = chunkByYears(startDate, endDate);
    } else {
        chunks = chunkByMonths(startDate, endDate);
    }

    let allCheckIns: ClothingCheckIn[] = [];

    for (const [chunkStart, chunkEnd] of chunks) {
        const checkIns = await getGuestClothingChunk(chunkStart, chunkEnd, guestID || 'all');
        allCheckIns.push(...checkIns);
        await new Promise(resolve => setTimeout(resolve, 50)); // 50ms delay
    }

    return allCheckIns;
};


export const deleteClothingCheckin = async (checkinEvent: ClothingCheckIn) => {
    const session = await Auth.currentSession();
    const idToken = session.getIdToken().getJwtToken();

    const url = new URL(`${process.env.REACT_APP_API_ENDPOINT}/api/checkin/${checkinEvent.CheckInID}/clothing`);
    url.searchParams.append('event-time', String(Math.floor(checkinEvent.date.getTime() / 1000)));

    const response = await fetch(url, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${idToken}`,
        },
    });

    return await response.json();
};





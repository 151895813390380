import React, { useState } from "react";
import { Box, Form, FormField, Button, CheckBox } from "grommet";

interface LoginFormProps {
    onSubmit: (username: string, password: string) => void;
}

const LoginForm: React.FC<LoginFormProps> = ({ onSubmit }) => {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);

    const handleSubmit = () => {
        onSubmit(username, password);
    };

    return (
        <Box>
            <Form onSubmit={() => handleSubmit()}>
                <FormField
                    label="Username"
                    name="username"
                    id="username"
                    value={username}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => setUsername(event.target.value)}
                    required
                />
                <FormField
                    label="Password"
                    name="password"
                    id="password"
                    type={showPassword ? "text" : "password"}
                    value={password}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => setPassword(event.target.value)}
                    required
                />
                <Box pad={{ bottom: "medium" }}>
                    <CheckBox
                        label="Show Password"
                        checked={showPassword}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => setShowPassword(event.target.checked)}
                    />
                </Box>
                <Button type="submit" primary label="Login" />
            </Form>
        </Box>
    );
};

export default LoginForm;

import { Button, Box, Layer, Text } from 'grommet';
import { FormClose } from 'grommet-icons';

interface PopupProps {
    dates: Date[];
    onDelete: (Date: Date) => void;
    onClose: () => void;
}

const Popup: React.FC<PopupProps> = ({ dates, onDelete, onClose }) => {
    return (
        <Layer onEsc={onClose} onClickOutside={onClose} position="center">
            <Box pad="medium" gap="small" width="medium">
                <Box direction="row" justify="between" align="center">
                    <Text weight="bold" size="large">Event Dates</Text>
                    <Button icon={<FormClose />} onClick={onClose} />
                </Box>
                {dates.map((date, i) => (
                    <Box key={i} direction="row" justify="between" align="center">
                        <Text>{date.toLocaleString()}</Text>
                        <Button label="Delete" onClick={() => onDelete(date)} />
                    </Box>
                ))}
            </Box>
        </Layer>
    );
};

export default Popup;

import React, { useState } from 'react';
import {Box, Button, Text} from 'grommet';
import { Checkmark, Close, Trash } from 'grommet-icons';
import { VolunteerCheckIn } from "../../types/CheckInEvent";

interface VolunteerCheckInItemProps {
    event: VolunteerCheckIn;
    onDelete: (event: VolunteerCheckIn) => void;
}

const VolunteerCheckInItem: React.FC<VolunteerCheckInItemProps> = ({ event, onDelete }) => {
    const [selectedToDelete, setSelectedToDelete] = useState(false);

    const handleDelete = () => {
        setSelectedToDelete(true);
    };

    const handleConfirmDelete = () => {
        if (selectedToDelete) {
            onDelete(event);
            setSelectedToDelete(false);
        }
    };

    const handleCancelDelete = () => {
        setSelectedToDelete(false);
    };

    return (
        <Box direction="row" border="bottom" height="xsmall" pad="small" gap="medium">
            <Box flex="grow" direction="column" justify="between">
                <Text>{event.guest.UserName}</Text>
                <Text> Working Area: {event.area}</Text>
                <Text>{event.hours || "Missing Hours"} {event.minutes || "Missing Minutes"}</Text>
            </Box>
            <Box align="end" justify={"center"} fill width={{min:"120px"}}>
                {selectedToDelete ? (
                    <Box direction="row">
                        <Button
                            onClick={handleConfirmDelete}
                            icon={<Box pad="xsmall" round="full" background="status-ok"><Checkmark /></Box>}
                        />
                        <Button
                            onClick={handleCancelDelete}
                            icon={<Box pad="xsmall" round="full" background="status-critical"><Close /></Box>}
                        />
                    </Box>
                ) : (
                    <Button icon={<Trash />} onClick={() => handleDelete()} />
                )}
            </Box>
        </Box>
    );
};

export default VolunteerCheckInItem;

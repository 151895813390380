export enum EventType {
  CLOTHING = "Clothing",
  LAUNDRY = "Laundry",
  KITCHEN = "Kitchen",
  CLOTHINGCHECKIN = "Clothing Check-In",
  SHOWER = "Shower",
  MAILROOM = "Mail Room",
  CLASSROOM = "Classroom",
  FOOD = "Food Pantry",
  VOLUNTEER = "Volunteer",
  REPORT = "Report",
  NaN = "",
}

export const EventServiceId: { [key in EventType]: string } = {
  [EventType.LAUNDRY]: "Laundry",
  [EventType.KITCHEN]: "Kitchen",
  [EventType.CLOTHING]: "Clothing",
  [EventType.CLOTHINGCHECKIN]: "ClothingCheckIn",
  [EventType.SHOWER]: "Shower",
  [EventType.MAILROOM]: "Mail",
  [EventType.CLASSROOM]: "Classroom",
  [EventType.FOOD]: "Food",
  [EventType.VOLUNTEER]: "Volunteer",
  [EventType.REPORT]: "Report",
  [EventType.NaN]: "",
}

//list of event types to exclude from lists
export const ExcludeEventTypes: EventType[] = [
    EventType.NaN,
    EventType.CLOTHINGCHECKIN
]
// src/Components/ResetPasswordForm/ResetPasswordForm.tsx
import React, { useState } from "react";
import { Box, Form, FormField, Button, CheckBox } from "grommet";

interface ResetPasswordFormProps {
    onSubmit: (newPassword: string, confirmNewPassword: string) => void;
}

const ResetPasswordForm: React.FC<ResetPasswordFormProps> = ({ onSubmit }) => {
    const [newPassword, setNewPassword] = useState("");
    const [confirmNewPassword, setConfirmNewPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);

    const handleSubmit = () => {
        onSubmit(newPassword, confirmNewPassword);
    };

    return (
        <Box>
            <Form onSubmit={handleSubmit}>
                <FormField
                    label="New Password"
                    name="newPassword"
                    type={showPassword ? "text" : "password"}
                    value={newPassword}
                    onChange={(event) => setNewPassword(event.target.value)}
                    required
                />
                <FormField
                    label="Confirm New Password"
                    name="confirmNewPassword"
                    type={showPassword ? "text" : "password"}
                    value={confirmNewPassword}
                    onChange={(event) => setConfirmNewPassword(event.target.value)}
                    required
                />
                <Box pad={{ bottom: "medium" }}>
                    <CheckBox
                        label="Show password"
                        checked={showPassword}
                        onChange={(event) => setShowPassword(event.target.checked)}
                    />
                </Box>
                <Button type="submit" primary label="Reset Password" />
            </Form>
        </Box>
    );
};

export default ResetPasswordForm;

import React, {useState} from "react";
import {Box, RadioButtonGroup, Select, Form, FormField, Button, TextInput} from "grommet";
import {v4 as uuid} from "uuid";
import {useMutation, useQuery, useQueryClient} from 'react-query'
import { EventServiceId, EventType } from "../../types/EventType";
import { User } from "../../types/User";
import { FoodCheckIn } from "../../types/CheckInEvent";
import FoodCheckInItem from "./FoodCheckInItem";
import SectionForm from "../../common/SectionForm";
import GuestFoodDetails from "./GuestFoodDetails";
import {getFoodCheckIn, postFoodCheckIn} from "../../Api/foodPantryApi";
import {deleteCheckin} from "../../Api/api";

const Food: React.FC = () => {

    const [phoneNumber, setPhoneNumber] = useState("");

    const [seniorCount, setSeniorCount] = useState("");
    const [adultCount, setAdultCount] = useState("");
    const [childrenCount, setChildrenCount] = useState("");

    const [homelessStatus, setHomelessStatus] = useState("");
    const [foodBoxDesignation, setFoodBoxDesignation] = useState("");

    const [reset, setReset] = useState(false);
    const [user, setUser] = useState<User | null>(null);


    //TODO find a better way of doing this
    const [userList, setUserList] = useState<User[]>([]);
    const [selectedUser, setSelectedUser] = useState<User | null>(null);

    const startDay = new Date();
    startDay.setHours(0, 0, 0, 0);

    // Get the end of the current day
    const endDay = new Date();
    endDay.setHours(23, 59, 59, 999);

    const queryClient = useQueryClient();

    const { data: checkedInEvents} = useQuery<FoodCheckIn[], Error>(
        ['getFoodCheckIn', startDay, endDay],
        () => getFoodCheckIn(startDay, endDay),
        {
            staleTime: 1000 * 60 * 10, // Fetch a fresh data every time we open the component
            cacheTime: 1000*60*10, //ten minutes

        }
    );

    const deleteEventMutation = useMutation(deleteCheckin, {
        onSuccess: (data) => {
            if(data.message === "Success") {
                // On success, invalidate and refetch the query
                queryClient.invalidateQueries(['getFoodCheckIn', startDay, endDay]);
            }
        },
    });

    const deleteEvent = (event: FoodCheckIn) => {
        deleteEventMutation.mutate(event);
    };


    const fetchFoodCheckIn = async (): Promise<FoodCheckIn[]> => {
        if(selectedUser){
            return await getFoodCheckIn(startDay, endDay, selectedUser.GuestID);
        }
        return [];
    }

    const {data: foodCheckInEvents } =
        useQuery<FoodCheckIn[], Error>(`${selectedUser?.GuestID}-Food`, fetchFoodCheckIn, {
            staleTime: 1000 * 60 * 10, // ten minutes
            cacheTime: 1000*60*10, //ten minutes
        });

    const getFoodDetails = async (): Promise<string> => {

        if(!foodCheckInEvents){
            return "Error fetching food details.";
        }

        //if food CheckInEvents is empty return nothing
        if (foodCheckInEvents.length === 0){
            return ""
        }

        const now = new Date();
        const oneWeekAgo = new Date();
        oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);

        // Sort the foodCheckInEvents array in descending order by date
        const sortedEvents = foodCheckInEvents.sort((a, b) => {
            return b.date.getTime() - a.date.getTime();
        });

        // Set the lastFood event to the first one in the sorted array (closest to now)
        let lastFood = sortedEvents[0];

        // Check if the lastFood event was within the last week
        if (lastFood.date >= oneWeekAgo && lastFood.date <= now) {
            return "This user has already received food on " + lastFood.date.toLocaleDateString() + ".";
        }

        return ""; // return "" if no error
    };


    const handleSubmit = () => {
        // Handle submission here
        if (user === null){
            return;
        }

        const newEvent: FoodCheckIn = {
            CheckInID: uuid(),
            serviceID: EventServiceId[EventType.FOOD],
            date: new Date(),
            guest: user,
            seniorCount: seniorCount,
            adultCount: adultCount,
            childrenCount: childrenCount,
            phoneNumber: phoneNumber,
            homelessStatus: homelessStatus,
            foodBoxDesignation: foodBoxDesignation
        };

        postFoodCheckInMutation.mutate(newEvent);

        setSelectedUser(null)
        setUserList([])

        setReset(!reset);
    }

    const postFoodCheckInMutation = useMutation(postFoodCheckIn, {
        onSuccess: (data) => {
            if (data.message === 'Success') {
                queryClient.invalidateQueries(['getFoodCheckIn', startDay, endDay]);
                queryClient.invalidateQueries(`${selectedUser?.GuestID}-Food`);
            }
        },
    });

    const handleCheckIn = (user: User) => {
        setUser(user);
        setUserList([])
    }

    const resetForm = () => {
        setChildrenCount("");
        setAdultCount("");
        setSeniorCount("");

        setPhoneNumber("");

        setHomelessStatus("");
        setFoodBoxDesignation("");
    };

    function validatePhone(phoneNumber: string): string | undefined {
        if (phoneNumber === "" || phoneNumber === undefined) {
            return "Required";
        }

        const phoneRegex = /^\(?(\d{3})\)?[\s-]?(\d{3})[\s-]?(\d{4})$/;

        // Using the regex to test the phoneNumber
        if (!phoneRegex.test(phoneNumber)) {
            return "Invalid phone number.";
        }

        // If none of the above, then the phone number is valid
        return undefined;
    }


    return (
        <Box align={"center"} overflow={'auto'} pad={"small"}>
            <SectionForm
                eventType={EventType.FOOD}
                CheckInEventItem={FoodCheckInItem}
                initialCheckInEvents={checkedInEvents || []}
                onUserSelected={handleCheckIn}
                getDetails={getFoodDetails}
                deleteEvent={deleteEvent}
                setSelectedUser={setSelectedUser}
                setUserList={setUserList}
                reset={reset}
            >
                <Box width="medium" align="center" fill={"horizontal"}>
                    <Box width="medium" align="center">
                        <Form onSubmit={() => { handleSubmit(); resetForm(); }}>

                            <FormField
                                name="phoneNumber"
                                label="Phone"
                                validate={() => validatePhone(phoneNumber)}
                            >
                                <TextInput
                                    value={phoneNumber}
                                    onChange={(event) => setPhoneNumber(event.target.value)}
                                />
                            </FormField>

                            <FormField
                                name="numberOfSeniors"
                                key={"numberOfSeniors"}
                                label="Number of Seniors"
                                required
                            >
                                <RadioButtonGroup
                                    key={"numberOfSeniors"}
                                    name="numberOfSeniors"
                                    options={['0', '1', '2', '3', '4', '5', '5+']}
                                    value={seniorCount}
                                    direction={"row"}
                                    onChange={(event) => {
                                        setSeniorCount(event.target.value);
                                    }}
                                />
                            </FormField>

                            <FormField
                                name="numberOfAdults"
                                key={"numberOfAdults"}
                                label="Number of Adults"
                                required
                            >
                                <RadioButtonGroup
                                    key={"numberOfAdults"}
                                    name="numberOfAdults"
                                    options={[' 0', ' 1', ' 2', ' 3', ' 4', ' 5', ' 5+']}
                                    value={adultCount}
                                    direction={"row"}
                                    onChange={(event) => {
                                        setAdultCount(event.target.value);
                                    }}
                                />
                            </FormField>

                            <FormField
                                name="numberOfChildren"
                                label="Number of Children Under 18"
                                required
                            >
                                <RadioButtonGroup
                                    name="numberOfChildren"
                                    options={['0 ', '1 ', '2 ', '3 ', '4 ', '5 ', '5+ ']}
                                    value={childrenCount}
                                    direction={"row"}
                                    onChange={(event) => {
                                        setChildrenCount(event.target.value);
                                    }}
                                />
                            </FormField>

                            <FormField
                                name="homelessStatus"
                                label="Homeless Status"
                                required
                            >
                                <Select
                                    name={"homelessStatus"}
                                    placeholder="Choose an option"
                                    options={["Homeless", "Temporary Housing", "Permanent Housing"]}
                                    value={homelessStatus}
                                    onChange={({ option }) => setHomelessStatus(option)}
                                />
                            </FormField>

                            <FormField
                                name="foodBoxDesignation"
                                label="Food Box Designation"
                                required
                            >
                                <Select
                                    name={"foodBoxDesignation"}
                                    placeholder="Choose an option"
                                    options={["Non-Perishable Food Bag", "Perishable Food Bag"]}
                                    value={foodBoxDesignation}
                                    onChange={({ option }) => setFoodBoxDesignation(option)}
                                />
                            </FormField>

                            <Button type="submit" label="Submit" primary />
                        </Form>

                    </Box>
                </Box>
            </SectionForm>

            <Box>

                {userList.length !== 0 && (
                    <GuestFoodDetails selectedUser={selectedUser?.GuestID} UserList={userList} />
                )}
            </Box>

        </Box>
    );
};

export default Food;
// src/Components/LoginHandler/LoginHandler.tsx
import React, {useEffect, useState} from "react";
import {Box, Button, Heading, Text} from "grommet";
import {Auth} from "aws-amplify";
import LoginForm from "./LoginForm";
import ResetPasswordForm from "./ResetPasswordForm";


interface LoginHandlerProps {
    onClose: () => void;
}

const LoginHandler: React.FC<LoginHandlerProps> = ( {onClose}) => {
    const [loginState, setLoginState] = useState<"login" | "resetPassword" | "loggedIn">("login");
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [user, setUser] = useState<any>(undefined);

    // Add this useEffect hook to check if the user is already logged in when the component mounts
    useEffect(() => {
        const checkUser = async () => {
            try {
                const currentUser = await Auth.currentAuthenticatedUser();
                setUser(currentUser);
                setLoginState("loggedIn");
            } catch (error) {
                // The user is not currently signed in
                setLoginState("login");
            }
        };
        checkUser().then();
    }, []);

    const loginUser = async (username: string, password: string) => {
        setErrorMessage("");
        setSuccessMessage("");
        try {
            const user = await Auth.signIn(username, password);
            setUser(user);
            setLoginState("loggedIn");
            //call the api
            if(user.challengeName === 'NEW_PASSWORD_REQUIRED'){
                setLoginState("resetPassword");
            }
        } catch (error: any) {
            console.error('Error signing in:', error);
            setErrorMessage(error.message);
        }
    };

    const resetPassword = async (newPassword: string, confirmNewPassword: string) => {

        setErrorMessage("");
        setSuccessMessage("");

        if(newPassword !== confirmNewPassword){
            setErrorMessage("Passwords do not match");
            return;
        }

        if(user){
            Auth.completeNewPassword(
                user,
                newPassword,
                {
                    email: user.email,
                }
            ).then(async (user: any) => {
                setUser(user);
                setLoginState("loggedIn");
                onClose();
            }).catch((e: any) => {
                console.error('Error Setting New Password:', e);
                setErrorMessage('Error setting new password');
            });
        }
    };

    const handleLogout = async () => {

        setErrorMessage("");
        setSuccessMessage("");

        try {
            await Auth.signOut();
            setUser(undefined);
            setLoginState("login");
            setSuccessMessage('Logged out successfully');
        } catch (error: any) {
            console.error('Error signing out:', error);
            setErrorMessage('Error signing out');
        }
    };

    return (
        <Box margin={{ horizontal: "medium", bottom: "small" }} width={"medium"}>
            {loginState !== "loggedIn" && (
                <Box justify={"center"} align={"center"}>
                    <Heading level={'3'} margin={{ bottom: "medium" }}>Login</Heading>
                </Box>
            )}
            {loginState === "login" && <LoginForm onSubmit={loginUser}/>}
            {loginState === "resetPassword" && <ResetPasswordForm onSubmit={resetPassword} />}
            {loginState === "loggedIn" && (
                <Box justify={'center'} align={'center'}>
                    <Box justify={'center'} align={'center'}>
                        <Heading level={'3'}  margin={{ bottom: "medium" }}>Current User</Heading>
                        <Text size="medium" margin={{ bottom: "medium" }}>{user.attributes.email}</Text>
                    </Box>
                    <Button label="Logout" onClick={handleLogout}/>
                </Box>
            )}

            {errorMessage && (
                <Box pad={{ vertical: "small" }} align={"center"} margin={{ top: "medium" }}>
                    <Text color="status-error">{errorMessage}</Text>
                </Box>
            )}
            {successMessage && (
                <Box pad={{ vertical: "small" }} align={"center"} margin={{ top: "medium" }}>
                    <Text>{successMessage}</Text>
                </Box>
            )}
        </Box>
    );
};

export default LoginHandler;

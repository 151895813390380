// src/components/Clothing.tsx
import React, {useEffect, useState} from "react";
import {
    Heading,
    Layer,
    Box,
    Button,
    Text, Select, TextInput,
} from "grommet";
import { v4 as uuid } from 'uuid';
import CheckinForm from "../../common/CheckIn/CheckinForm";

import MonthlyChart from "./monthlyChart";

import {EventServiceId, EventType} from "../../types/EventType";
import { User } from "../../types/User";
import ClothingTypeDropdown from "./ClothingTypeDropdown";
import {deleteClothingCheckin, getGuestClothing, postGuestClothing} from "../../Api/clothingApi";
import { ClothingType } from "../../types/ClothingType";
import CheckInList from "../../common/CheckIn/CheckInList";
import {ClothingCheckIn} from "../../types/CheckInEvent";
import {useQueryClient} from "react-query";
import {checkinGuest} from "../../Api/api";

const Clothing: React.FC = () => {
    const [clothingEvents, setClothingEvents] = useState<ClothingCheckIn[]>([]);
    const [checkedIn, setCheckedIn] = useState(false);
    const [checkedInTrigger, setCheckedInTrigger] = useState(false);
    const [reset, setReset] = useState(false);
    const [user, setUser] = useState<User | undefined>();
    const [showError, setShowError] = useState(false);
    const [category, setCategory] = useState("");

    const currentDate = new Date();

// setting first day of the current month
    const firstDay = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
    const [startDate, setStartDate] = useState(firstDay);

// setting last day of the current month
    const lastDay = new Date(currentDate.getFullYear(), currentDate.getMonth() + 7, 0);
    const [endDate, setEndDate] = useState(lastDay);

    const months = [
        'January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December'
    ];

    const [startMonth, setStartMonth] = useState(months[startDate.getMonth()]);
    const [startYear, setStartYear] = useState(startDate.getFullYear());

    const [endMonth, setEndMonth] = useState(months[endDate.getMonth()]);
    const [endYear, setEndYear] = useState(endDate.getFullYear());

    useEffect(() => {
        setReset(false);
    }, [reset]);

    useEffect(() => {

        updateClothingEvents();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [startDate, endDate, user]);


    const updateClothingEvents = async () => {

        if (!user) {
            return;
        }

        const initialStartDate = new Date(startDate.getFullYear() - 1, startDate.getMonth(), startDate.getDate());
        const clothingEvents = await getGuestClothing(initialStartDate, endDate, user.GuestID);
        setClothingEvents(clothingEvents);
    }

    const handleCheckin = async (user: User | null) => {
        if (!user) {
            setShowError(true);
            return;
        }

        setCheckedInTrigger(!checkedInTrigger);

        await checkinGuest(user.GuestID, EventServiceId[EventType.CLOTHING], uuid())

        setCheckedIn(true);
        setUser(user);
    };

    const createNewClothingEvent = (user: User, category: ClothingType, currentDate: Date): ClothingCheckIn => {
        return {
            CheckInID: uuid(),
            serviceID: EventServiceId[EventType.CLOTHING],
            date: currentDate,
            guest: user,
            category: category,
        };
    };

    const handleSubmitCategory = async () => {
        if (!user) {
            console.error("User is not set");
            return;
        }

        const currentDate = new Date();
        const newEvent = createNewClothingEvent(user, category as ClothingType, currentDate);


        setClothingEvents(prevEvents => [...prevEvents, newEvent]);
        setCategory("");
        setReset(true);

        await postGuestClothing(newEvent)
    };

    const handleStartDateChange = (month: string, year: string) => {

        //if the year is less than 2016 then set the year to 2016
        if (Number(year) < 2016) {
            year = "2016";
        }

        const newDate = new Date(Number(year), months.indexOf(month), 1);
        setStartDate(newDate);
    };

    const handleEndDateChange = (month: string, year: string) => {
        const newDate = new Date(Number(year), months.indexOf(month), 1);
        setEndDate(newDate);
    };

    const queryClient = useQueryClient();

    const handleFinish = () => {


        const startDay = new Date();
        startDay.setHours(0, 0, 0, 0);

        // Get the end of the current day
        const endDay = new Date();
        endDay.setHours(23, 59, 59, 999);

        queryClient.invalidateQueries(['getGuestClothing', startDay, endDay])

        setClothingEvents([]);
        setUser(undefined);
        setCheckedIn(false)
    }

    return (
        <>
            {!checkedIn && (
                <CheckinForm eventType={EventType.CLOTHING} onCheckin={handleCheckin}>
                    <CheckInList eventType={EventType.CLOTHING} reRenderTrigger={checkedInTrigger} deletable={true}/>
                </CheckinForm>
            )}

            {showError && (
                <Layer
                    onEsc={() => setShowError(false)}
                    onClickOutside={() => setShowError(false)}
                >
                    <Box pad="medium" gap="small" width="medium">
                        <Heading level={3} margin="none">
                            Error
                        </Heading>
                        <Text>User selection failed. Please try again.</Text>
                        <Button label="Okay" onClick={() => setShowError(false)} primary />
                    </Box>
                </Layer>
            )}

            {checkedIn && (
                <Box >
                    <Box pad={{horizontal:"small", bottom:"large"}}>
                        <Heading level={'3'}>{user?.UserName}</Heading>
                    </Box>


                    <Box direction={"row"} justify={"between"} margin={{bottom:"small"}} style={{flexShrink: 0}} pad={{horizontal:"small"}}>
                        <Box gap={"small"}  width={"large"} >
                            <Box direction="row" gap="medium">
                                <Box width={"medium"} >
                                    <ClothingTypeDropdown
                                        value={category}
                                        onValueChange={(selectedValue) => setCategory(selectedValue)}
                                        reset={reset}
                                    />
                                </Box>
                                <Button label="Submit"  onClick={handleSubmitCategory} primary/>
                            </Box>
                            <Box direction="row" gap="small" align={"center"} justify={"start"} >
                                <Text>From:</Text>
                                <Select
                                    options={months}
                                    value={startMonth}
                                    onChange={({ option }) => {
                                        setStartMonth(option);
                                        handleStartDateChange(option, startYear.toString());
                                    }}
                                />
                                <Box width={"small"}>
                                    <TextInput
                                        type="number"
                                        value={startYear}
                                        onChange={(event) => {
                                            setStartYear(parseInt(event.target.value));
                                            handleStartDateChange(startMonth, event.target.value);
                                        }}
                                    />
                                </Box>
                                <Text>To:</Text>
                                <Select
                                    options={months}
                                    value={endMonth}
                                    onChange={({ option }) => {
                                        setEndMonth(option);
                                        handleEndDateChange(option, endYear.toString());
                                    }}
                                />
                                <Box width={"small"}>
                                    <TextInput
                                        type="number"
                                        value={endYear}
                                        onChange={(event) => {
                                            setEndYear(parseInt(event.target.value));
                                            handleEndDateChange(endMonth, event.target.value);
                                        }}
                                    />
                                </Box>
                            </Box>
                        </Box>

                        <Box>
                            <Button label="Close"  onClick={() => handleFinish()} primary/>
                        </Box>

                    </Box>

                    <Box
                        overflow={'auto'}
                    >
                        <MonthlyChart
                            parentClothingEvents={clothingEvents}
                            onCategoryClick={(clickedCategory: string) => {
                                setReset(!reset)

                                if(category === clickedCategory){
                                    handleSubmitCategory()
                                    setCategory("")
                                    return
                                }else{
                                    setCategory(clickedCategory)
                                }

                            }}

                            onDelete={(clothingCheckIn) => {
                                deleteClothingCheckin(clothingCheckIn).then(() => {
                                    setClothingEvents(clothingEvents.filter(event => {
                                        return event.CheckInID !== clothingCheckIn.CheckInID
                                    }))
                                });
                            }}

                            selectedCategory={category}
                            endDate={endDate}
                            startDate={startDate}
                        />
                    </Box>
                </Box>
            )}
        </>
    );
};

export default Clothing;
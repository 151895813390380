// Flatten nested objects
import CheckinEventHandler from "./CheckinEventHandler";

function formatKey(key: string): string {
    return key.charAt(0).toUpperCase() + key.slice(1);
}

function flattenObject(obj: Record<string, any>): Record<string, any> {
    return Object.entries(obj).reduce((acc, [key, value]) => {

        const formattedKey = formatKey(key);

        if (value instanceof Date) {

            // Format Date object to string (e.g., "1/10/2024")
            acc[formattedKey] = value.toLocaleDateString('en-US', { year: 'numeric', month: 'numeric', day: 'numeric' });
        } else if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
            // Recursively flatten nested objects
            Object.assign(acc, flattenObject(value));
        } else {
            // Directly assign non-object values
            acc[formattedKey] = value;
        }
        return acc;
    }, {} as Record<string, any>);
}


// Extract unique keys from events, excluding keys with empty values across all events
function getAllUniqueKeys(events: Array<Record<string, any>>, exclusionList: string[]): Set<string> {
    const keysWithValue = new Set<string>();

    events.forEach(event => {
        const flatEvent = flattenObject(event);
        Object.entries(flatEvent).forEach(([key, value]) => {
            if (!exclusionList.includes(key) && value !== '' && value !== null && value !== undefined) {
                keysWithValue.add(key);
            }
        });
    });

    return keysWithValue;
}


// Process each individual event
function processEvent(event: Record<string, any>, allKeys: Set<string>): Record<string, any> {
    const flatEvent = flattenObject(event);

    return Array.from(allKeys).reduce((acc, key) => {
        acc[key] = flatEvent[key] ?? '';
        return acc;
    }, {} as Record<string, any>);
}

// Convert each event field to a CSV-safe format
function convertToCsvField(value: any): string {
    if (typeof value === 'string') {
        // Escape double quotes and enclose the string in double quotes
        return `"${value.replace(/"/g, '""')}"`;
    } else {
        // For non-string, non-Date values, return as is
        return String(value);
    }
}

// Create CSV content from processed events
function generateCsvContent(events: Array<Record<string, any>>, allKeys: Set<string>): string {
    return events.map(event => {
        const processedEvent = processEvent(event, allKeys);
        // Convert values to CSV-safe strings
        return Object.values(processedEvent).map(convertToCsvField).join(',');
    }).join('\n');
}


// Main function to generate and download CSV
export function downloadCsv(): void {
    const checkinEventHandler = CheckinEventHandler.getInstance();
    const regularEvents = checkinEventHandler.getEvents();
    const specialEvents = checkinEventHandler.getSpecialEvents();

    // Combine regular events and special events
    const combinedEvents = [...regularEvents, ...specialEvents];

    // List of keys to exclude from CSV None for now
    const exclusionList: string[] = ['DateID', 'CheckInID', 'GuestID', 'CheckedOut'];

    const allKeys = getAllUniqueKeys(combinedEvents, exclusionList);
    const csvContent = generateCsvContent(combinedEvents, allKeys);
    const headers = Array.from(allKeys).join(',');
    const csvData = `${headers}\n${csvContent}`;

    const blobData: Blob = new Blob([csvData], { type: 'text/csv' });
    const url: string = window.URL.createObjectURL(blobData);
    const link: HTMLAnchorElement = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('download', "event_data.csv");
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}

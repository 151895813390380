import React, { useState, useEffect } from "react";
import {Button, Box, Text, Layer} from "grommet";
import { ClothingType } from "../../types/ClothingType";
import { categorizedClothingTypes } from "../../types/ClothingCatagories";
import {Close, LinkPrevious} from "grommet-icons";


interface ClothingTypeDropdownProps {
    onValueChange: (selectedValue: string) => void;
    value: string;
    reset: boolean;
}

const ClothingTypeDropdown: React.FC<ClothingTypeDropdownProps> = ({ onValueChange, value, reset }) => {
    const [categoryIndex, setCategoryIndex] = useState(-1);
    const [show, setShow] = useState(false);

    useEffect(() => {
        if (reset) {
            setCategoryIndex(-1);
        }
    }, [reset]);

    const handleCategorySelect = (index: number) => {
        setCategoryIndex(index);
    };

    const handleItemSelect = (item: string) => {
        setShow(false);
        onValueChange(item as ClothingType);

    };

    return (

        <Box>
            <Button label={value || "Select a clothing type"} onClick={() => setShow(true)}/>
            {show && (
                <Layer
                    animation={false}
                    onEsc={() => setShow(false)}
                    onClickOutside={() => {
                        setShow(false);
                        onValueChange("");
                        setCategoryIndex(-1);
                    }}
                >
                    <Box direction={"row"} justify={(categoryIndex !== -1) ? "between": "end"}>
                        {categoryIndex !== -1 && (<Button icon={<LinkPrevious />} onClick={() => setCategoryIndex(-1)}/>)}
                        <Button icon={<Close/>} onClick={() => {
                            setShow(false);
                            setCategoryIndex(-1)
                        }}/>
                    </Box>


                    <Box height={"2px"} background={"light-4"} margin={{horizontal:"small", bottom:"small"}}/>

                    <Box flex overflow="auto" height={{max:"50vh"}}>


                        {categoryIndex === -1 ? (
                            categorizedClothingTypes.map((category, index) => (
                                <Box pad="medium" hoverIndicator="light-4" onClick={() => handleCategorySelect(index)} focusIndicator={false} style={{ minHeight: 'max-content' }}>
                                    <Button key={category.category}>
                                        <Text margin={"small"}>{category.category}</Text>
                                    </Button>
                                </Box>
                            ))
                        ) : (
                            categorizedClothingTypes[categoryIndex].items.map((item) => (
                                <Box pad="medium" hoverIndicator="light-4" onClick={() => handleItemSelect(item)} focusIndicator={false} style={{ minHeight: 'max-content' }}>
                                    <Button key={item}>
                                        <Text>{item}</Text>
                                    </Button>
                                </Box>
                            ))
                        )}
                    </Box>
                </Layer>
            )}
        </Box>
    );
};

export default ClothingTypeDropdown;

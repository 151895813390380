// src/components/Laundry/Laundry.tsx
import React, {useEffect, useState} from "react";
import {Box, Heading, Button, Text, TextArea, Layer} from "grommet";
import UserForm from "./UserForm";
import UserList from "./UserList";
import AddUserModal from "./AddUserModal";
import {User} from "../../types/User"
import {getUsers, patchGuestNote} from "../../Api/api"
import {EventServiceId, EventType} from "../../types/EventType";
import {Flag} from "grommet-icons";
import {useQueryClient} from "react-query";
import GenderSelectionLayer from "./SexUpdate";


interface CheckinFormProps {
    eventType: EventType;
    onCheckin: (user: User | null) => Promise<void>;
    setSelectedUserParent?: (user: User | null) => void;
    setUserListParent?: (userList: User[]) => void;
    children?: React.ReactNode;
}

const CheckinForm: React.FC<CheckinFormProps> = ({eventType, onCheckin, setSelectedUserParent, setUserListParent, children}) => {
    const [users, setUsers] = useState<User[]>([]);


    const [selectedUser, setSelectedUser] = useState<User | null>(null);
    const [showAddUserModal, setShowAddUserModal] = useState(false);
    const [searchedName, setSearchedName] = useState("");
    const [searchedBirthday, setSearchedBirthday] = useState<Date>();
    const [userNotes, setUserNotes] = useState("");
    const [unsavedNotes, setUnsavedNotes] = useState<Map<string, string>>(new Map());
    const [reset, setReset] = useState(false);
    const [checkedInTrigger, setCheckedInTrigger] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [showSelectSex, setShowSelectSex] = useState(false);

    const queryClient = useQueryClient();

    const startDay = new Date();
    startDay.setHours(0, 0, 0, 0);

    const endDay = new Date();
    endDay.setHours(23, 59, 59, 999);


    useEffect(() => {
        if (selectedUser) {
            if (unsavedNotes.has(selectedUser.GuestID)) {
                // @ts-ignore
                setUserNotes(unsavedNotes.get(selectedUser.GuestID));
            } else {
                setUserNotes(selectedUser.Note);
            }
        } else {
            setUserNotes("");
        }
    }, [selectedUser, unsavedNotes]);

    const handleUserSelect = (user: User) => {
        if (setSelectedUserParent) {
            setSelectedUserParent(user);
        }
        setSelectedUser(user);
    };

    const handleFormSubmit = async (values: { name: string; birthday: Date | undefined}) => {

        const fetchedUsers = await getUsers(values.name, values.birthday);

        if (fetchedUsers.length === 0) {
            setError("No users found with the provided info.");
        } else {
            setError(null);
        }

        setUsers(fetchedUsers);
        if(setUserListParent){
            setUserListParent(fetchedUsers);
        }

        setSearchedName(values.name);
        setSearchedBirthday(values.birthday);

    };

    const handleAddUser = (values: { name: string; birthday: Date | undefined}) => {
        setSearchedName(values.name);
        if (values.birthday){
            setSearchedBirthday(values.birthday);
        }

        setShowAddUserModal(true);

    };

    const handleAddUserModalClose = async (name = "", birthday?: Date) => {
        setShowAddUserModal(false);
        if (birthday) {

            const fetchedUsers = await getUsers(name, birthday);
            setUsers(fetchedUsers);
        }
    };

    const handleSaveNote = async () => {

        if (selectedUser) {

            selectedUser.Note = userNotes;

            await patchGuestNote(selectedUser);

            // Update the unsavedNotes state to remove the saved note
            setUnsavedNotes(unsavedNotes => {
                const newUnsavedNotes = new Map(unsavedNotes);
                newUnsavedNotes.delete(selectedUser.GuestID);
                return newUnsavedNotes;
            });

            await queryClient.invalidateQueries(["getCheckIns",startDay, endDay, EventServiceId[eventType]]);


            setCheckedInTrigger(!checkedInTrigger);
        }
    };

    const hasUnsavedNote = () => {
        return selectedUser && unsavedNotes.has(selectedUser.GuestID);
    };

    const checkInUser = () => {

        if (!selectedUser) {
            return;
        }

        //if user gender is not Male or Female then set show select sex to true
        if (selectedUser.Gender === "N/A") {
            setShowSelectSex(true);
            return;
        }

        onCheckin(selectedUser).then(() => {
            const startDay = new Date();
            startDay.setHours(0, 0, 0, 0);

            const endDay = new Date();
            endDay.setHours(23, 59, 59, 999);

            queryClient.invalidateQueries(["getCheckIns", startDay, endDay, EventServiceId[eventType]]).catch(error => {
                console.error(error);
            })
        }).catch(error => {
            console.error(error);
        })

        // Save the note for the user if the note is unsaved
        if (hasUnsavedNote()) {
            handleSaveNote().catch(error => {
                console.error(error);
            });
        }

        setShowSelectSex(false);
        setSelectedUser(null)
        setSearchedBirthday(undefined)
        setSearchedName("")
        setUsers([])
        setReset(!reset)
    };

    return (
        <Box pad="medium" align={'center'} overflow={"auto"}>
            {eventType !== EventType.NaN && (<Heading level={2}>{eventType}</Heading>)}
            <Heading level={3}>{new Date().toLocaleDateString()}</Heading>
            <Box pad="medium" direction={'row'} >
                <Box justify="start" align={'center'} >
                    <UserForm onSubmit={handleFormSubmit} handleAddUser={handleAddUser} reset={reset}/>
                    {selectedUser && (
                        <Box fill={'horizontal'} align={'start'} >
                            <Box margin={{ top: "medium" }} direction={'row'}>
                                <Text size={"large"} margin={{right:"large"}}>
                                    {selectedUser.UserName} - {selectedUser.Birthday.toLocaleDateString('en-US', {month: '2-digit', day: '2-digit', year: '2-digit' })}
                                </Text>
                                {selectedUser.Note && <Flag color="red"/>}
                            </Box>

                            <Box fill={"horizontal"} height={"small"}>
                                <TextArea
                                    fill
                                    value={userNotes}
                                    onChange={event => {
                                        setUserNotes(event.target.value);
                                        if (selectedUser) {
                                            setUnsavedNotes(unsavedNotes => {
                                                const newUnsavedNotes = new Map(unsavedNotes);
                                                if (event.target.value === selectedUser.Note) {
                                                    newUnsavedNotes.delete(selectedUser.GuestID);
                                                } else {
                                                    newUnsavedNotes.set(selectedUser.GuestID, event.target.value);
                                                }
                                                return newUnsavedNotes;
                                            });
                                        }
                                    }}
                                />
                            </Box>


                            <Box direction="row" gap="medium" margin={{ top: "medium" }} fill={"horizontal"} justify={"between"}>
                                <Button primary label="Check In" onClick={() => {
                                    if (selectedUser && selectedUser.Gender !== "N/A") {
                                        checkInUser();
                                    }else{
                                        setShowSelectSex(true);
                                    }
                                }} />
                                <Button label="Cancel"
                                        onClick={() => {
                                            setSelectedUser(null);
                                            if (setSelectedUserParent) {
                                                setSelectedUserParent(null)
                                            }
                                        }}
                                />
                                {hasUnsavedNote() && <Button primary label="Save" onClick={handleSaveNote} />}
                            </Box>


                        </Box>
                    )}

                </Box>
                <Box align="center">
                    <UserList users={users} onSelect={handleUserSelect} eventType={eventType}/>
                </Box>
                <Box align="center">
                    {children}
                </Box>
            </Box>
            <AddUserModal
                open={showAddUserModal}
                onClose={handleAddUserModalClose}
                initialName={searchedName}
                initialBirthday={searchedBirthday}
            />
            {error && (
                <Layer
                    onEsc={() => setError(null)}
                    onClickOutside={() => setError(null)}
                >
                    <Box pad="medium" align="center">
                        <Text>{error}</Text>
                        <Box direction="row" justify="between" margin={{top:"medium"}} fill>
                            <Button label="Close" onClick={() => setError(null)} />

                            <Button primary label="Add User" onClick={() => {handleAddUser({ name: searchedName, birthday: searchedBirthday }); setError(null);}} />

                        </Box>
                    </Box>
                </Layer>
            )}
            {(showSelectSex && selectedUser) && (
                <GenderSelectionLayer
                    guest={selectedUser}
                    onClose={async () => {
                        setShowSelectSex(false);
                        checkInUser();
                    }}
                />
            )}
        </Box>
    );
};

export default CheckinForm;

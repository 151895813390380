// src/components/Mailroom.tsx
import React, {useState} from "react";
import CheckinForm from "../../common/CheckIn/CheckinForm";
import {EventServiceId, EventType} from "../../types/EventType";
import {User} from "../../types/User";
import CheckInList from "../../common/CheckIn/CheckInList";
import {checkinGuest} from "../../Api/api";
import {v4 as uuid} from "uuid";

const Mailroom: React.FC = () => {
    const [checkedInTrigger, setCheckedInTrigger] = useState(false);


    const handleCheckin = async (user: User | null) => {
        if (user) {
            await checkinGuest(user.GuestID, EventServiceId[EventType.MAILROOM], uuid(),)

            setCheckedInTrigger(!checkedInTrigger);
        }

    };

    return (
        <CheckinForm eventType={EventType.MAILROOM} onCheckin={handleCheckin}>
            <CheckInList eventType={EventType.MAILROOM} reRenderTrigger={checkedInTrigger}/>
        </CheckinForm>
    );
};

export default Mailroom;

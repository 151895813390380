import React, {useState, useEffect, FC, ReactNode} from "react";
import {Box, Heading} from "grommet";
import CheckinForm from "./CheckIn/CheckinForm"
import {EventType} from "../types/EventType";
import { User } from "../types/User";
import GenericCheckInList from "./GenericCheckInList";
import ErrorLayer from "./ErrorLayer";

interface SectionFormProps<T> {
    eventType: EventType,
    CheckInEventItem: FC<any>,
    initialCheckInEvents: T[],
    getDetails?: (userID: string) => Promise<string | ReactNode>,
    deleteEvent: (event: T) => void,
    onUserSelected: (user: User) => void,
    setUserList?: (userList: User[]) => void,
    setSelectedUser?: (user: User | null) => void,
    reset: boolean,
    children: ReactNode
}

export const SectionForm: FC<SectionFormProps<any>> = ({
    eventType,
    CheckInEventItem,
    initialCheckInEvents,
    getDetails,
    deleteEvent,
    onUserSelected,
    setUserList,
    setSelectedUser,
    reset,
    children
}) => {
    const [checkedIn, setCheckedIn] = useState(false);
    const [user, setUser] = useState<User | null>(null);
    const [showError, setShowError] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string | ReactNode>("");
    const [checkedInEvents, setCheckedInEvents] = useState<any[]>(initialCheckInEvents);


    useEffect(() => {
        if (user && getDetails) {
            getDetails(user.GuestID).then(message => {
                if(message !== "") {
                    setShowError(true);
                    setErrorMessage(message);
                }
            });
        }
        //we don't want the error re-rendering everytime that a state changes
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    useEffect(() => {
        if (user) {
            setUser(null);
            setCheckedIn(false);
        }
        //no user otherwise it will immediately check out
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reset]);

    useEffect(() => {
        setCheckedInEvents(initialCheckInEvents);
    }, [initialCheckInEvents]);

    const handleCheckin = async (selectedUser: User | null) => {
        if (selectedUser) {
            try {
                setUser(selectedUser);
                setCheckedIn(true);
                onUserSelected(selectedUser);
            } catch (error) {
                console.error(error);
                setShowError(true);
                setErrorMessage("An error occurred during check in.");
            }
        } else {
            console.error("User selection failed. Please try again.");
            return;
        }
    };

    return (
        <Box>
            {!checkedIn && (
                <CheckinForm
                    eventType={eventType}
                    onCheckin={handleCheckin}
                    setSelectedUserParent={setSelectedUser}
                    setUserListParent={setUserList}
                >
                    {checkedInEvents.length !== 0 &&
                        <GenericCheckInList
                            checkInEvents={checkedInEvents}
                            onDelete={deleteEvent}
                            ItemComponent={CheckInEventItem}
                        />}
                </CheckinForm>
            )}

            {showError && (
                <ErrorLayer
                    errorMessage={errorMessage}
                    onOverride={() => {
                        setShowError(false);
                        setErrorMessage("");
                        setCheckedIn(true)
                    }}
                    onCancel={() => {
                        setUser(null);
                        setCheckedIn(false);
                        setShowError(false);
                        setErrorMessage("");
                    }}
                />
            )}

            {checkedIn && user && (
                <Box width="medium" align="center" fill={"horizontal"}>
                    <Heading level={2}>{eventType}</Heading>
                    <Heading level={3}>{new Date().toLocaleDateString()}</Heading>
                    <Box width="medium" align="center" margin={{top:"medium"}}>
                        {children}
                    </Box>
                </Box>
            )}
        </Box>
    );
};

export default SectionForm;

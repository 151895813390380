import {FoodCheckIn} from "../types/CheckInEvent";
import {Auth} from "aws-amplify";
import {getCheckIns} from "./api";
import {EventServiceId, EventType} from "../types/EventType";

export const postFoodCheckIn = async (foodCheckIn: FoodCheckIn) => {
    const session = await Auth.currentSession();
    const idToken = session.getIdToken().getJwtToken();

    const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/api/checkin/${foodCheckIn.CheckInID}/food`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${idToken}`,
        },
        body: JSON.stringify({
            GuestID: foodCheckIn.guest.GuestID,
            ChildrenCount: foodCheckIn.childrenCount.trim(),
            AdultCount: foodCheckIn.adultCount.trim(),
            SeniorCount: foodCheckIn.seniorCount.trim(),
            HomelessStatus: foodCheckIn.homelessStatus,
            FoodBoxDesignation: foodCheckIn.foodBoxDesignation,
            PhoneNumber: foodCheckIn.phoneNumber
        }),
    });

    return await response.json();
}

export const getFoodCheckIn = async (startDate: Date, endDate: Date, guestID=""): Promise<FoodCheckIn[]> => {
    const checkIns = await getCheckIns(startDate, endDate, EventServiceId[EventType.FOOD], guestID);
    return checkIns.map(item => item as FoodCheckIn);
};

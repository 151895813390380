import React, {useEffect, useState} from "react";
import { Box, Form, FormField, TextInput, DateInput, Button, Text } from "grommet";
import {Auth} from "aws-amplify";
import {dataFormat} from "../../types/Constants";

interface UserFormProps {
    onSubmit: (values: { name: string; birthday: Date | undefined }) => void;
    handleAddUser: (values: { name: string; birthday: Date | undefined }) => void;
    reset: boolean
}

const UserForm: React.FC<UserFormProps> = ({ onSubmit, handleAddUser, reset }) => {
    const [errorMessage, setErrorMessage] = useState("");
    const [name, setName] = useState("");
    const [birthday, setBirthday] = useState("");
    const [hasSearched, setHasSearched] = useState(false);

    useEffect(() => {
        setName("");
        setBirthday("");
        setErrorMessage("");
        setHasSearched(false);
    }, [reset]);

    const validateDate = (dateStr: string) => {

        if(!dateStr) return undefined

        if (!birthday) return "Invalid Date";
        let date = new Date(birthday);
        if (isNaN(date.getTime())) return "Date format is incorrect. Please use mm/dd/yyyy.";
        if (date > new Date()) return "Date cannot be in the future.";
        return undefined;
    };

    const handleSubmit = (event: any) => {
        event.preventDefault();
        Auth.currentAuthenticatedUser()
            .then(() => {
                if (name || (!isNaN(new Date(birthday).getTime()))) { // Check if at least one field is filled
                    setHasSearched(true);
                    onSubmit({ name, birthday: birthday ? new Date(birthday) : undefined });
                    setErrorMessage(""); // Clear error message
                } else {
                    setErrorMessage("Either name or birthday must be provided.");
                }
            })
            .catch(() => {
                setErrorMessage("You are not logged in. Please login to proceed.");
            });
    };


    return (
        <Box width="medium">
            <Form onSubmit={handleSubmit}>
                <FormField label="Name" name="name">
                    <TextInput
                        name="name"
                        value={name}
                        onChange={event => setName(event.target.value)}
                        autoComplete="off"
                    />
                </FormField>
                <FormField label="Birthday*" name="birthday" validate={validateDate}>
                    <DateInput
                        format={dataFormat}
                        name="birthday"
                        value={birthday}
                        onChange={(event) => setBirthday(event.value as string)}
                        autoComplete="off"
                        icon={<></>}
                    />
                </FormField>
                <Box direction="row" justify="between" margin={{ top: "medium" }}>
                    {hasSearched && (
                        <Button
                            label="Add User"
                            onClick={(event) => {

                                event.preventDefault();
                                const form = event.currentTarget.closest("form");
                                if (!form) return;
                                const formData = new FormData(form);
                                const name = formData.get("name") as string;
                                const birthdayString = formData.get("birthday") as string;

                                if (!isNaN(new Date(birthdayString).getTime())) {
                                    handleAddUser({ name, birthday: new Date(birthdayString) });
                                } else {
                                    handleAddUser({ name, birthday: undefined });
                                }
                            }}
                        />
                    )}
                    <Button type="submit" label="Search" primary />
                </Box>
            </Form>
            <Box pad={{top:"medium"}}>
                {errorMessage && <Text color="status-error">{errorMessage}</Text>}
            </Box>
        </Box>
    );
};

export default UserForm;


import React, {useState} from "react";
import {Box, Pagination, Heading} from "grommet";

interface GenericCheckInListProps<T> {
    checkInEvents: T[];
    onDelete: (event: T) => void;
    ItemComponent: React.FC<{event: T, onDelete: (event: T) => void}>;
}

const GenericCheckInList: React.FC<GenericCheckInListProps<any>> = ({ checkInEvents, onDelete, ItemComponent }) => {
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 8; // change this to your desired items per page

    // Sort checkInEvents by date in descending order
    const sortedCheckInEvents = [...checkInEvents].sort((a, b) => b.date.getTime() - a.date.getTime());

    // Paginate the sortedCheckInEvents
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = sortedCheckInEvents.slice(indexOfFirstItem, indexOfLastItem);

    return (
        <Box fill="vertical" pad={{horizontal:"medium"}} align={"center"} margin={{horizontal:"medium"}} overflow={'auto'}>
            <Heading level={"3"}>Checked In</Heading>
            <Box style={{flexShrink: 0}} >
                {currentItems.map(event => (
                    <ItemComponent key={event.date.toString()} event={event} onDelete={onDelete} />
                ))}
            </Box>


            <Box align="center" pad="medium" flex={false} justify="end">
                <Pagination
                    numberItems={sortedCheckInEvents.length}
                    onChange={({ page }) => setCurrentPage(page)}
                    page={currentPage}
                    step={itemsPerPage}
                />
            </Box>
        </Box>
    );
};

export default GenericCheckInList;

import {Box, Button, Heading, Layer, TextArea, Text, Tip} from "grommet";
import React, { useState } from 'react';
import { Flag } from "grommet-icons";
import {User} from "../types/User";
import {patchGuestNote} from "../Api/api";
import {EventServiceId, EventType} from "../types/EventType";
import {useQueryClient} from "react-query";

interface ErrorLayerProps {
    user: User;
    eventType: EventType
}

const ErrorLayer: React.FC<ErrorLayerProps> = ({ user, eventType }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [userNotes, setUserNotes] = useState(user.Note);
    const [errorMessage, setErrorMessage] = useState<string | null>(null); // new state for error message

    const queryClient = useQueryClient();

    const onCancel = () => {
        setIsOpen(false);
        setErrorMessage(null); // clear any existing error message when closing the layer
    }
    const onSave = async () => {

        user.Note = userNotes;

        patchGuestNote(user).then(() => {

            setIsOpen(false);

            const startDay = new Date();
            startDay.setHours(0, 0, 0, 0);

            const endDay = new Date();
            endDay.setHours(23, 59, 59, 999);

            queryClient.invalidateQueries(["getCheckIns",startDay, endDay, EventServiceId[eventType]]);

        }).catch(() => {
            setErrorMessage('Failed to save'); // set error message when save fails
        });

    }

    return (
        <>
            <Box onClick={() => setIsOpen(true)} focusIndicator={false}>
                <Tip content="User Note">
                    <Flag color='red'/>
                </Tip>
            </Box>

            {isOpen && (
                <Layer
                    onClickOutside={onCancel}
                    onEsc={onCancel}
                >
                    <Box
                        pad="medium"
                        gap="small"
                        width={{min: '300px', max: '700px', width: '20vw'}}
                        height={{min: '300px', max: '700px', height: '50vh'}}
                        justify={"between"}
                    >
                        <Heading level={3} margin="none">
                            User Note
                        </Heading>

                        <TextArea
                            value={userNotes}
                            fill
                            onChange={event => setUserNotes(event.target.value)}
                            resize={false}
                        />
                        {errorMessage && <Text color='status-error'>{errorMessage}</Text>}
                        <Box direction="row" justify="between" pad={"small"} flex={"grow"} height={{max: '70px'}}>
                            <Button
                                label="Cancel"
                                onClick={onCancel}
                            />
                            <Button
                                label="Save"
                                onClick={onSave}
                                primary
                            />
                        </Box>
                    </Box>
                </Layer>
            )}
        </>
    )
};

export default ErrorLayer;

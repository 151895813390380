import {Box, DataTable, Heading} from "grommet";
import React from 'react';
import CheckinEventHandler from "./CheckinEventHandler";
import {EventType} from "../../types/EventType";

const ShowerDataSummary: React.FC = () => {

    let filteredData = CheckinEventHandler.getInstance().getSpecialEvents(EventType.SHOWER)

    const columns = [
        { property: 'DateID', header: 'Date'},
        { property: 'MaleShowered', header: 'Male Showered' },
        { property: 'FemaleShowered', header: 'Female Showered' },
        { property: 'Total', header: 'Total Showered', render: (rowData: any) => rowData.MaleShowered + rowData.FemaleShowered }
    ];

    return (
        <Box style={{flexShrink: 0}}>
            <Heading level={2}>Shower Data Summary</Heading>
            <DataTable columns={columns} data={filteredData} paginate={true}/>
        </Box>

    );
}

export default ShowerDataSummary;

import React, { useEffect, useState } from 'react';
import {Box} from 'grommet';
import { User } from "../../types/User";
import GuestFoodDetailsItem from "./GuestFoodDetailsItem";

interface GuestFoodDetailsProps {
    selectedUser?: string;
    UserList: User[];
}

type UserComponentMap = {
    [GuestID: string]: JSX.Element;
}

const GuestFoodDetails: React.FC<GuestFoodDetailsProps> = ({ selectedUser: selectedGuestID = "", UserList }) => {

    // eslint-disable-next-line
    const [_, setNonSelectedComponents] = useState<UserComponentMap>({});
    const [selectedComponents, setSelectedComponents] = useState<UserComponentMap>({});

    // Update nonSelectedComponents and selectedComponents only when a new user is added
    useEffect(() => {
        const newNonSelectedComponents: UserComponentMap = {};
        const newSelectedComponents: UserComponentMap = {};

        UserList.forEach(user => {
            if (user.GuestID === selectedGuestID) {
                if (!newSelectedComponents[user.GuestID]) {
                    newSelectedComponents[user.GuestID] = <GuestFoodDetailsItem key={user.GuestID} user={user} selected={true} />;
                }
            } else {
                if (!newNonSelectedComponents[user.GuestID]) {
                    newNonSelectedComponents[user.GuestID] = <GuestFoodDetailsItem key={user.GuestID} user={user} selected={false} />;
                }
            }
        });
        setNonSelectedComponents(newNonSelectedComponents);
        setSelectedComponents(newSelectedComponents);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [UserList, selectedGuestID]);

    return (
        <Box direction="row" wrap={true} gap={"small"} justify={'center'} pad={"medium"} style={{flexShrink: 0}}>
            {selectedComponents[selectedGuestID]}
        </Box>
    );
};

export default GuestFoodDetails;

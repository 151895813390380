import { Layer, Box, Button, Text, Heading } from 'grommet';
import { User } from "../../types/User";
import {patchGuestSex} from "../../Api/api";

interface GenderSelectionLayerProps {
    guest: User
    onClose: () => void;
}

const GenderSelectionLayer: React.FC<GenderSelectionLayerProps> = ({
   guest,
   onClose,
}) => {

    const  handleSelectGender = async (gender: 'Male' | 'Female') => {

        guest.Gender = gender

        await patchGuestSex(guest)
        onClose();
    };

    return (
        <Layer
            responsive={false}
        >
            <Box fill="vertical" justify="center" align="center" pad="medium" gap="medium">
                <Heading level={3} margin="none">Client is missing database information</Heading>
                <Text>Please select one of the following:</Text>
                <Box direction="row" gap="medium">
                    <Button primary label="Male" onClick={() => handleSelectGender('Male')} />
                    <Button primary label="Female" onClick={() => handleSelectGender('Female')} />
                </Box>
            </Box>
        </Layer>
    );
};

export default GenderSelectionLayer;

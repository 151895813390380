import React, {useState} from 'react';
import {Box, Select, Form, FormField, TextInput, TextArea, Button, Layer, Heading} from 'grommet';
import { v4 as uuid } from 'uuid';
import { EventServiceId, EventType } from '../../types/EventType';
import { User } from '../../types/User';
import {ClassroomCheckIn} from '../../types/CheckInEvent';
import {ClassroomClass} from "../../types/ClassType";
import {LengthOption} from "../../types/LengthOption";
import CheckinForm from "../../common/CheckIn/CheckinForm";
import {getClass, postClass, postClassCheckIn} from "../../Api/classroomApi";
import CheckInList from "../../common/CheckIn/CheckInList";
import {useQuery, useQueryClient} from "react-query";


const Classroom: React.FC = () => {
    const queryClient = useQueryClient();

    const [selectedClass, setSelectedClass] = useState("");
    const [newClass, setNewClass] = useState<ClassroomClass>({
        name: "",
        dates: [],
        description: "",
        length: "15min"
    });
    const [showLayer, setShowLayer] = useState(false);
    const [classSelected, setClassSelected] = useState(false);


    const startDay = new Date();
    startDay.setHours(0, 0, 0, 0);

    // Get the end of the current day
    const endDay = new Date();
    endDay.setHours(23, 59, 59, 999);


    const {data: classData} = useQuery<ClassroomClass[], Error>('classes', getClass, {
        staleTime: 1000 * 60 * 10, // ten minutes
        cacheTime: 1000 * 60 * 10, // ten minutes
    });

    const handleCheckin = async (user: User | null) => {
        // Handle submission here
        if (user === null || selectedClass === "") {
            return;
        }

        const newEvent: ClassroomCheckIn = {
            CheckInID: uuid(),
            serviceID: EventServiceId[EventType.CLASSROOM],
            date: new Date(),
            guest: user,
            className: selectedClass
        };
        //post check in
        const response: any = await postClassCheckIn(newEvent)

        if (response.message === "Success") {
            await queryClient.invalidateQueries(["getCheckIns",startDay, endDay, EventServiceId[EventType.CLASSROOM]]);
        }
    }

    const handleClassSubmit = () => {
        if (selectedClass === "") {
            return;
        }
        setClassSelected(true);
    }

    const resetForm = () => {
        setSelectedClass("");
        setClassSelected(false);
    };

    const addNewClass = async () => {

        const response: any = await postClass(newClass)

        if(response.message === "Success"){
            await queryClient.invalidateQueries('classes');
        }

        setShowLayer(false);
    };

    const classFilter = (checkin: ClassroomCheckIn) => {
        return checkin.className === selectedClass;
    };


    return (
        <Box>

            {!classSelected && (
                <Box width="medium" align="center" fill={"horizontal"}>

                    <Heading level={2}>Select a Class to Check In</Heading>
                    <Heading level={3}>{new Date().toLocaleDateString()}</Heading>

                    <Box width="medium" align="center">
                        <Form onSubmit={() => {
                            handleClassSubmit();
                        }}>
                            <FormField name="class" label="Class">
                                <Select
                                    placeholder="Choose a class"
                                    options={(classData || []).map(c => c.name)}
                                    value={selectedClass}
                                    onChange={({option}) => setSelectedClass(option)}/>
                            </FormField>
                            <Box justify="between" direction="row" pad={{top: 'medium'}}>
                                <Button label="New Class" onClick={() => setShowLayer(true)}/>
                                <Button type="submit" label="Submit" primary/>
                            </Box>
                        </Form>


                    </Box>
                </Box>
            )}

            {classSelected && (
                <Box align="center" fill={"horizontal"}>
                    <Heading level={2}>Class - {selectedClass}</Heading>
                    <CheckinForm eventType={EventType.NaN} onCheckin={handleCheckin}>
                        <CheckInList eventType={EventType.CLASSROOM} reRenderTrigger={true} filter={classFilter} />
                    </CheckinForm>
                    <Button size="large" label="Finish" onClick={resetForm} primary/>
                    <Box height="xxsmall"/>
                </Box>
            )}

            {showLayer && (
                <Layer onEsc={() => setShowLayer(false)} onClickOutside={() => setShowLayer(false)}>
                    <Box
                        pad="medium"
                    >
                        <Form onSubmit={addNewClass}>
                            <FormField name="newClass" label="Name">
                                <TextInput
                                    placeholder="Class name"
                                    value={newClass.name}
                                    onChange={event => setNewClass({...newClass, name: event.target.value})}/>
                            </FormField>


                            <FormField name="description" label="Description">
                                <Box height={"medium"} width={"large"}>
                                    <TextArea
                                        resize={false}
                                        placeholder="Class description"
                                        value={newClass.description}
                                        fill
                                        onChange={event => setNewClass({
                                            ...newClass,
                                            description: event.target.value
                                        })}/>
                                </Box>
                            </FormField>


                            <FormField name="length" label="Length">
                                <Select
                                    placeholder="Choose a length"
                                    options={[
                                        LengthOption.FifteenMinutes,
                                        LengthOption.ThirtyMinutes,
                                        LengthOption.FortyFiveMinutes,
                                        LengthOption.OneHour,
                                        LengthOption.OverTwoHours,
                                    ]}
                                    value={newClass.length}
                                    onChange={({option}) => setNewClass({
                                        ...newClass,
                                        length: option as LengthOption
                                    })}/>
                            </FormField>


                            <Box direction={'row'} justify={'between'} margin={{top:'large'}}>
                                <Button label="Cancel" onClick={() => setShowLayer(false)}/>
                                <Button type="submit" label="Add Class" primary/>
                            </Box>


                        </Form>
                    </Box>
                </Layer>
            )}
        </Box>
    );
};

export default Classroom;

// src/common/CheckInList.tsx
import React, {useMemo, useState} from "react";
import {Box, Button, Heading, List, Pagination, Text, Tip} from "grommet";
import {CheckinEvent} from "../../types/CheckInEvent";
import {Checkmark, Close, Trash} from "grommet-icons";
import {deleteCheckin, getCheckIns} from "../../Api/api";
import {EventServiceId, EventType} from "../../types/EventType";
import {useQuery, useQueryClient, useMutation} from "react-query";
import CustomFlag from "../CustomFlag";

interface CheckInListProps {
    eventType: EventType;
    reRenderTrigger: boolean;
    deletable?: boolean;
    filter?: (event: any) => boolean;
}

const CheckInList: React.FC<CheckInListProps> = ({ eventType, deletable = true, filter }) => {
    const itemsPerPage = 8;

    const [selectedToDelete, setSelectedToDelete] = useState<CheckinEvent | null>(null);
    const [startIndexOfPage, setStartIndexOfPage] = useState(0);
    const [endIndexOfPage, setEndIndexOfPage] = useState(itemsPerPage);

    const queryClient = useQueryClient();

    const startDay = new Date();
    startDay.setHours(0, 0, 0, 0);

    // Get the end of the current day
    const endDay = new Date();
    endDay.setHours(23, 59, 59, 999);


    const { data: checkedInEvents} = useQuery<
        CheckinEvent[], Error>(

        ['getCheckIns', startDay, endDay, EventServiceId[eventType]],
        () => getCheckIns(startDay, endDay, EventServiceId[eventType]),
        {
            staleTime: 1000 * 60 * 10, // ten minutes
            cacheTime: 1000 * 60 * 10, // ten minutes
        }
    );


    const deleteCheckInMutation = useMutation(deleteCheckin, {
        onSuccess: () => {
            queryClient.invalidateQueries('getCheckIns');
        },
    });

    const handleDelete = (event: CheckinEvent) => {
        if (selectedToDelete) {
            setSelectedToDelete(null); // Reset the selection if a user is already selected to delete
        } else {
            setSelectedToDelete(event);
        }
    };

    const deleteUser = (event: CheckinEvent) => {
        deleteCheckInMutation.mutate(event);
    };

    const handleConfirmDelete = () => {
        if (selectedToDelete) {
            deleteUser(selectedToDelete);
            setSelectedToDelete(null);
        }
    };

    const handleCancelDelete = () => {
        setSelectedToDelete(null);
    };

    const sortedCheckInEvents = useMemo(() => {
        let sortedEvents = [...(checkedInEvents || [])].sort((a, b) => b.date.getTime() - a.date.getTime());

        // If a filter function is provided, apply it
        if (filter) {
            sortedEvents = sortedEvents.filter(filter);
        }

        return sortedEvents;
    }, [checkedInEvents, filter]);


    return (sortedCheckInEvents || []).length > 0 ? (
        <Box fill="vertical" pad={{horizontal:"medium"}} align={"center"} margin={{horizontal:"medium"}} overflow={"auto"}>
            <Heading level={"3"}>Checked In</Heading>
            <List
                pad={{vertical:"xxsmall"}}
                border={false}
                data={sortedCheckInEvents.slice(startIndexOfPage, endIndexOfPage)}
                itemKey={(event: CheckinEvent) => {
                    return event.CheckInID
                }}
                primaryKey={(event: CheckinEvent) => (
                    <Box direction="row" height={{min:"70px"}} align="center" justify="between">
                        <Text>
                            {event.guest.UserName} - {event.guest.Gender} {new Date(event.guest.Birthday).toLocaleDateString("en-US", { month: '2-digit', day: '2-digit', year: '2-digit' })}
                        </Text>
                            <Box width={{min:deletable ? "150px" : "0px"}} margin={{start:"small"}} justify={event.guest.Note ? 'between': 'end'} direction="row" align={'center'}>
                                {event.guest.Note && <CustomFlag user={event.guest} eventType={eventType}/>}
                                {selectedToDelete === event ? (
                                    <Box direction="row">
                                        <Tip content="Confirm">
                                            <Button
                                                onClick={handleConfirmDelete}
                                                icon={<Box pad="xsmall" round="full" background="status-ok"><Checkmark /></Box>}
                                            />
                                        </Tip>
                                        <Tip content="Cancel">
                                            <Button
                                                onClick={handleCancelDelete}
                                                icon={<Box pad="xsmall" round="full" background="status-critical"><Close /></Box>}
                                            />
                                        </Tip>
                                    </Box>
                                ) : (
                                    deletable ? (
                                        <Tip content="Delete">
                                            <Button icon={<Trash />} onClick={() => handleDelete(event)} />
                                        </Tip>
                                    ) : (
                                        <>
                                        </>
                                    )
                                )}
                            </Box>
                    </Box>
                )}
            />
            <Box align="center" pad="medium" flex={false} justify="end">
                <Pagination
                    numberItems={sortedCheckInEvents.length}
                    onChange={({ _, startIndex, endIndex }) => {
                        setStartIndexOfPage(startIndex);
                        setEndIndexOfPage(endIndex);
                    }}
                    page={0}
                    step={itemsPerPage}
                />
            </Box>
        </Box>
    ) : (
        <>
        </>
    );
};

export default CheckInList;

// src/common/UserList.tsx
import React from "react";
import { Box, List, Text, Button } from "grommet";
import {User} from "../../types/User"
import CustomFlag from "../CustomFlag";
import {EventType} from "../../types/EventType";

interface UserListProps {
    users: User[];
    onSelect: (user: User) => void;
    eventType: EventType
}

const UserList: React.FC<UserListProps> = ({ users, onSelect, eventType }) => {
    return (
        <Box overflow={'auto'}>
            <List
                border={false}
                data={users}
                pad="medium"
                itemKey={(user: User) => user.GuestID}
                primaryKey={(user: User) => (
                    <Box direction="row" gap="medium" align="center">
                        <Button label="Select" onClick={() => onSelect(user)} />
                        <Text>
                            {user.UserName} - {user.Birthday.toLocaleDateString("en-US", { month: '2-digit', day: '2-digit', year: '2-digit' })}
                        </Text>
                        {user.Note && <CustomFlag user={user} eventType={eventType}/>}
                    </Box>
                )}
            />
        </Box>
    );
};

export default UserList;

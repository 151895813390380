import React, {useEffect, useState} from "react";
import {Box, Button} from "grommet";
import {Menu} from "grommet-icons";
import {useQuery} from 'react-query';

import {
    CheckinEvent,
    ClassroomCheckIn,
    ClothingCheckIn,
    FoodCheckIn,
    VolunteerCheckIn
} from "../../types/CheckInEvent";
import {EventServiceId, EventType} from "../../types/EventType";

import {getFoodCheckIn} from "../../Api/foodPantryApi";
import {getCheckIns} from "../../Api/api";
import {getShowerEvents} from "../../Api/ShowerApi";
import {getClassCheckIn} from "../../Api/classroomApi";
import {getGuestVolunteer} from "../../Api/volunteerApi";
import {getGuestClothing} from "../../Api/clothingApi";

import ReportSelection from "./ReportSelection";

import DataRenderer from "./DataRenderer";
import {getKitchenEvents} from "../../Api/kitchenApi";
import {KitchenEvent, ShowerEvent} from "../../types/CalendarEvent";
import CheckinEventHandler, {CheckInEventUnion} from "./CheckinEventHandler";
import {downloadCsv} from "./CreateCSV";

interface SelectedDates {
    start: Date;
    end: Date;
}

type EventTypeDictionary = {
    [key in EventType]?: QueryObject
};

type QueryObject = {
    data: CheckInEventUnion[] | undefined
    isLoading: boolean | undefined;
    isError: boolean | undefined;
    error: Error | null;
};

const Report: React.FC = () => {

    const [selectedService, setSelectedService] = useState<EventType[]>([EventType.LAUNDRY]);
    const [selectedDates, setSelectedDates] = useState<SelectedDates>({start: setTimeToStartOfDay(new Date()), end: setTimeToEndOfDay(new Date())});
    const [showSidebar, setShowSidebar] = useState<boolean>(true);
    const [checkinEventHandler] = useState<CheckinEventHandler>(
        CheckinEventHandler.getInstance()
    );

    let queries: EventTypeDictionary = {};
    const twelveHours = 12 * 60 * 60 * 1000;


    useEffect(() => {
        checkinEventHandler.setServiceEventType(selectedService);
        // eslint-disable-next-line
    }, [selectedService]);



    const handleSuccess = (serviceEventType: EventType, data: any) => {
        checkinEventHandler.handleData(serviceEventType, data);
    };

    function setTimeToStartOfDay(date: Date): Date {
        return new Date(date.setHours(0, 0, 0, 0));
    }
    function setTimeToEndOfDay(date: Date): Date {
        return new Date(date.setHours(23, 59, 59, 999));
    }

    // For Laundry
    queries[EventType.LAUNDRY] = useQuery<CheckinEvent[], Error>(
        [EventServiceId[EventType.LAUNDRY] + "-Report", selectedDates.start, selectedDates.end],
        () => getCheckIns(selectedDates.start, selectedDates.end, EventServiceId[EventType.LAUNDRY]),
        {
            staleTime: twelveHours,
            cacheTime: twelveHours,
            enabled: selectedService.includes(EventType.LAUNDRY),
            onSuccess: (data:CheckinEvent[]) => handleSuccess(EventType.LAUNDRY, data),
        }
    );

    // For Clothing
    queries[EventType.CLOTHING] = useQuery<ClothingCheckIn[], Error>(
        [EventServiceId[EventType.CLOTHING] + "-Report", selectedDates.start, selectedDates.end],
        () => getGuestClothing(selectedDates.start, selectedDates.end),
        {
            staleTime: twelveHours,
            cacheTime: twelveHours,
            enabled: selectedService.includes(EventType.CLOTHING),
            onSuccess: (data:ClothingCheckIn[]) => handleSuccess(EventType.CLOTHING, data),
        }
    );

    // For Clothing Check Ins
    queries[EventType.CLOTHINGCHECKIN] = useQuery<CheckinEvent[], Error>(
        [EventServiceId[EventType.CLOTHINGCHECKIN] + "-Report", selectedDates.start, selectedDates.end],
        () => getCheckIns(selectedDates.start, selectedDates.end, EventServiceId[EventType.CLOTHING]),
        {
            enabled: selectedService.includes(EventType.CLOTHING),
            staleTime: twelveHours,
            cacheTime: twelveHours,
            onSuccess: (data:CheckinEvent[]) => handleSuccess(EventType.CLOTHINGCHECKIN, data),
        }
    );

    // For Mail
    queries[EventType.MAILROOM] = useQuery<CheckinEvent[], Error>(
        [EventServiceId[EventType.MAILROOM] + "-Report", selectedDates.start, selectedDates.end],
        () => getCheckIns(selectedDates.start, selectedDates.end, EventServiceId[EventType.MAILROOM]),
        {
            staleTime: twelveHours,
            cacheTime: twelveHours,
            enabled: selectedService.includes(EventType.MAILROOM),
            onSuccess: (data:CheckinEvent[]) => handleSuccess(EventType.MAILROOM, data),
        }
    );

    // For Classroom
    queries[EventType.CLASSROOM]  = useQuery<ClassroomCheckIn[], Error>(
        [EventServiceId[EventType.CLASSROOM] + "-Report", selectedDates.start, selectedDates.end],
        () => getClassCheckIn(selectedDates.start, selectedDates.end),
        {
            staleTime: twelveHours,
            cacheTime: twelveHours,
            enabled: selectedService.includes(EventType.CLASSROOM),
            onSuccess: (data:ClassroomCheckIn[]) => handleSuccess(EventType.CLASSROOM, data),
        }
    );

    // For Food
    queries[EventType.FOOD] = useQuery<FoodCheckIn[], Error>(
        [EventServiceId[EventType.FOOD] + "-Report", selectedDates.start, selectedDates.end],
        () => getFoodCheckIn(selectedDates.start, selectedDates.end),
        {
            staleTime: twelveHours,
            cacheTime: twelveHours,
            enabled: selectedService.includes(EventType.FOOD),
            onSuccess: (data:FoodCheckIn[]) => handleSuccess(EventType.FOOD, data),
        }
    );

    // For Volunteer
    queries[EventType.VOLUNTEER] = useQuery<VolunteerCheckIn[], Error>(
        [EventServiceId[EventType.VOLUNTEER] + "-Report", selectedDates.start, selectedDates.end],
        () => getGuestVolunteer(selectedDates.start, selectedDates.end),
        {
            staleTime: twelveHours,
            cacheTime: twelveHours,
            enabled: selectedService.includes(EventType.VOLUNTEER),
            onSuccess: (data) => handleSuccess(EventType.VOLUNTEER, data),
        }
    );

    // For Kitchen
    queries[EventType.KITCHEN] = useQuery<KitchenEvent[], Error>(
        [EventServiceId[EventType.KITCHEN] + "-Report", selectedDates.start, selectedDates.end],
        () => getKitchenEvents(selectedDates.start, selectedDates.end),
        {
            staleTime: twelveHours,
            cacheTime: twelveHours,
            enabled: selectedService.includes(EventType.KITCHEN),
            onSuccess: (data: KitchenEvent[]) => handleSuccess(EventType.KITCHEN, data),
        }
    );

    // For Shower
    queries[EventType.SHOWER] = useQuery<ShowerEvent[], Error>(
        [EventServiceId[EventType.SHOWER] + "-Report", selectedDates.start, selectedDates.end],
        () => getShowerEvents(selectedDates.start, selectedDates.end),
        {
            staleTime: twelveHours,
            cacheTime: twelveHours,
            enabled: selectedService.includes(EventType.SHOWER),
            onSuccess: (data) => handleSuccess(EventType.SHOWER, data),
        }
    );

    return (
        <Box
            direction={"row"}
            gap={"small"}
            height={{min: '100vh'}}
        >

            <ReportSelection
                setSelectedDates={setSelectedDates}
                setSelectedService={setSelectedService}
                showSidebar={showSidebar}
                setShowSidebar={setShowSidebar}
                downloadCsv={downloadCsv}
            />

            <Box direction={"column"} fill>
                <Box width={{min: '150px', max: '10vw'}} justify={"start"} align={"center"} pad={"medium"}>
                    <Button icon={<Menu/>} onClick={() => setShowSidebar(!showSidebar)} fill/>
                </Box>

                <DataRenderer selectedService={selectedService}/>
            </Box>

        </Box>
    );
};

export default Report;


export enum ClothingType {
    PANTS = "Pants / Shorts / Sweatpants",
    SHIRT = "Shirt",
    TSHIRT = "T-Shirt",
    UNDERWEAR = "Underwear",
    SOCKS = "Socks",
    BRA = "Bra",
    DRESS = "Dress",
    SWEATSHIRT = "Sweater / Sweatshirt",
    BELT = "Belt / Suspenders",
    HAT = "Baseball Cap / Beanie",
    JACKET = "Hoodie / Jacket",
    SHOES = "Shoes",
    SANDALS = "Flip Flops / Shower Shoes",
    PURSE = "Tote Bag / Purse / Wallet",
    HANDKERCHIEF = "Handkerchief / Bandana",
    PAJAMAS = "Pajamas",
    BACKPACK = "Backpack / Suitcase",

    BANDAIDS = "Band-Aids",
    SHAMPOO_SMALL = "Shampoo / Conditioner - Small",
    SOAP_SMALL = "Soap / Bodywash - Small",
    LOTION_SMALL = "Lotion - Small",
    TOOTHPASTE_SMALL = "Toothpaste - Small",
    TOOTHPASTE_LARGE = "Toothpaste - Large",
    TOOTHBRUSH = "Toothbrush",
    MOUTHWASH = "Mouthwash",
    QTIPS = "Q-Tips",
    FEMININE_PRODUCTS = "Feminine Products",
    COMB_BRUSH = "Comb / Brush",
    SUNSCREEN_SMALL = "Sunscreen - Small",
    DENTAL_FLOSS = "Dental Floss",
    HAIR_TIES_CLIPS = "Hair Ties / Clips",
    TISSUE = "Tissue",
    PULL_UPS = "Pull-Ups",
    MASKS = "Masks",
    LIP_BALM = "Lip Balm / Chapstick",
    DEODORANT = "Deodorant",
    SHOE_STRINGS = "Shoe Strings",
    RAZOR = "Razor",
    NAIL_CLIPPERS = "Nail Clippers",
    NAIL_FILE = "Nail File",
    SHAVING_CREAM = "Shaving Cream",
    SHAMPOO_LARGE = "Shampoo / Conditioner - Large",
    SOAP_LARGE = "Soap / Bodywash - Large",
    LOTION_LARGE = "Lotion - Large",
    SUNSCREEN_LARGE = "Sunscreen - Large",
    DENTURE_CLEANER = "Denture Cleaner",
    FOOT_BABY_POWDER = "Foot / Baby Powder",
    TOILET_PAPER = "Toilet Paper",

    THERMAL_UNDERWEAR = "Thermal Underwear",
    COAT = "Coat",
    BEANIE = "Beanie",
    LINED_PANTS = "Fleece Lined Pants",
    SCARF = "Scarf",
    LIGHTWEIGHT_GLOVES = "Lightweight Gloves",
    LIGHTWEIGHT_BLANKET = "Lightweight Blanket",
    HEAVY_GLOVES = "Heavy Gloves",
    HEAVY_BLANKET = "Heavy Blanket",
    SLEEPING_BAG = "Sleeping Bag",
    RESCUE_BLANKET = "Rescue Blanket",
    HAND_TOE_WARMERS = "Hand / Toe Warmers",

    SUNGLASSES = "Sunglasses",
    READING_GLASSES = "Reading Glasses",
    CHARGERS = "Chargers",
    WATER_BOTTLES = "Water Bottles",
    RAIN_PONCHO = "Rain Poncho",
    BED_SHEETS = "Bed Sheets",
    PILLOW = "Pillow",
    WHEELCHAIR = "Cane / Crutches / Walker / Wheelchair",
    OTHER = "Other",
}

export interface Restriction {
    maxTimes: number;
    periodInMonths: number;
}

export const ClothingTypeRestrictions: Record<ClothingType, Restriction> = {
    [ClothingType.PANTS]: { maxTimes: 1, periodInMonths: 1 },
    [ClothingType.SHIRT]: { maxTimes: 1, periodInMonths: 1 },
    [ClothingType.TSHIRT]: { maxTimes: 1, periodInMonths: 1 },
    [ClothingType.UNDERWEAR]: { maxTimes: 1, periodInMonths: 1 },
    [ClothingType.SOCKS]: { maxTimes: 1, periodInMonths: 1 },
    [ClothingType.BRA]: { maxTimes: 1, periodInMonths: 1 },
    [ClothingType.DRESS]: { maxTimes: 1, periodInMonths: 6 },
    [ClothingType.SWEATSHIRT]: { maxTimes: 1, periodInMonths: 2 },
    [ClothingType.BELT]: { maxTimes: 1, periodInMonths: 2 },
    [ClothingType.HAT]: { maxTimes: 1, periodInMonths: 2 },
    [ClothingType.JACKET]: { maxTimes: 1, periodInMonths: 3 },
    [ClothingType.SHOES]: { maxTimes: 1, periodInMonths: 3 },
    [ClothingType.SANDALS]: { maxTimes: 1, periodInMonths: 3 },
    [ClothingType.PURSE]: { maxTimes: 1, periodInMonths: 3 },
    [ClothingType.HANDKERCHIEF]: { maxTimes: 1, periodInMonths: 3 },
    [ClothingType.PAJAMAS]: { maxTimes: 1, periodInMonths: 6 },
    [ClothingType.BACKPACK]: { maxTimes: 1, periodInMonths: 6 },
    [ClothingType.BANDAIDS]: { maxTimes: 1, periodInMonths: 1 },
    [ClothingType.SHAMPOO_SMALL]: { maxTimes: 2, periodInMonths: 1 },
    [ClothingType.SOAP_SMALL]: { maxTimes: 2, periodInMonths: 1 },
    [ClothingType.LOTION_SMALL]: { maxTimes: 2, periodInMonths: 1 },
    [ClothingType.TOOTHPASTE_SMALL]: { maxTimes: 2, periodInMonths: 1 },
    [ClothingType.TOOTHBRUSH]: { maxTimes: 1, periodInMonths: 2 },
    [ClothingType.MOUTHWASH]: { maxTimes: 1, periodInMonths: 1 },
    [ClothingType.QTIPS]: { maxTimes: 2, periodInMonths: 1 },
    [ClothingType.FEMININE_PRODUCTS]: { maxTimes: 2, periodInMonths: 1 },
    [ClothingType.COMB_BRUSH]: { maxTimes: 1, periodInMonths: 2 },
    [ClothingType.SUNSCREEN_SMALL]: { maxTimes: 2, periodInMonths: 1 },
    [ClothingType.DENTAL_FLOSS]: { maxTimes: 2, periodInMonths: 1 },
    [ClothingType.HAIR_TIES_CLIPS]: { maxTimes: 2, periodInMonths: 1 },
    [ClothingType.TISSUE]: { maxTimes: 2, periodInMonths: 1 },
    [ClothingType.PULL_UPS]: { maxTimes: 2, periodInMonths: 1 },
    [ClothingType.MASKS]: { maxTimes: 2, periodInMonths: 1 },
    [ClothingType.LIP_BALM]: { maxTimes: 1, periodInMonths: 2 },
    [ClothingType.DEODORANT]: { maxTimes: 1, periodInMonths: 2},
    [ClothingType.SHOE_STRINGS]: { maxTimes: 1, periodInMonths: 2 },
    [ClothingType.RAZOR]: { maxTimes: 1, periodInMonths: 2 },
    [ClothingType.NAIL_CLIPPERS]: { maxTimes: 1, periodInMonths: 12 },
    [ClothingType.NAIL_FILE]: { maxTimes: 1, periodInMonths: 1 },
    [ClothingType.SHAVING_CREAM]: { maxTimes: 1, periodInMonths: 2 },
    [ClothingType.SHAMPOO_LARGE]: { maxTimes: 1, periodInMonths: 2 },
    [ClothingType.SOAP_LARGE]: { maxTimes: 1, periodInMonths: 2 },
    [ClothingType.LOTION_LARGE]: { maxTimes: 1, periodInMonths: 2 },
    [ClothingType.TOOTHPASTE_LARGE]: { maxTimes: 1, periodInMonths: 2 },
    [ClothingType.SUNSCREEN_LARGE]: { maxTimes: 1, periodInMonths: 2 },
    [ClothingType.TOILET_PAPER]: { maxTimes: 1, periodInMonths: 1 },
    [ClothingType.DENTURE_CLEANER]: { maxTimes: 1, periodInMonths: 1 },
    [ClothingType.FOOT_BABY_POWDER]: { maxTimes: 1, periodInMonths: 1 },
    [ClothingType.THERMAL_UNDERWEAR]: { maxTimes: 1, periodInMonths: 12 },
    [ClothingType.COAT]: { maxTimes: 1, periodInMonths: 12 },
    [ClothingType.LINED_PANTS]: { maxTimes: 1, periodInMonths: 6 },
    [ClothingType.SCARF]: { maxTimes: 1, periodInMonths: 6 },
    [ClothingType.LIGHTWEIGHT_GLOVES]: { maxTimes: 1, periodInMonths: 6 },
    [ClothingType.LIGHTWEIGHT_BLANKET]: { maxTimes: 1, periodInMonths: 6 },
    [ClothingType.HEAVY_GLOVES]: { maxTimes: 1, periodInMonths: 12 },
    [ClothingType.BEANIE]: { maxTimes: 1, periodInMonths: 2 },
    [ClothingType.HEAVY_BLANKET]: { maxTimes: 1, periodInMonths: 12 },
    [ClothingType.SLEEPING_BAG]: { maxTimes: 1, periodInMonths: 12 },
    [ClothingType.RESCUE_BLANKET]: { maxTimes: 1, periodInMonths: 1 },
    [ClothingType.HAND_TOE_WARMERS]: { maxTimes: 2, periodInMonths: 1 },
    [ClothingType.SUNGLASSES]: { maxTimes: 1, periodInMonths: 6 },
    [ClothingType.READING_GLASSES]: { maxTimes: 1, periodInMonths: 6 },
    [ClothingType.CHARGERS]: { maxTimes: 1, periodInMonths: 6 },
    [ClothingType.WATER_BOTTLES]: { maxTimes: 1, periodInMonths: 6 },
    [ClothingType.RAIN_PONCHO]: { maxTimes: 1, periodInMonths: 1 },
    [ClothingType.BED_SHEETS]: { maxTimes: 1, periodInMonths: 6 },
    [ClothingType.PILLOW]: { maxTimes: 1, periodInMonths: 6 },
    [ClothingType.WHEELCHAIR]: { maxTimes: 1, periodInMonths: 12 },
    [ClothingType.OTHER]: { maxTimes: 1, periodInMonths: 1 },
};


export enum MinuteLengthOption {
    ZERO = "0 minutes",
    FIFTEEN = "15 minutes",
    THIRTY = "30 minutes",
    FOURTYFIVE = "45 minutes",
}

export enum HourLengthOption {
    ZEROHOURS = "0 Hours",
    ONEHOUR = "1 Hour",
    TWOHOURS = "2 Hours",
    THREEHOURS = "3 Hours",
    FOURHOURS = "4 Hours",
    FIVEHOURS = "5 Hours",
    SIXHOURS = "6 Hours",
    SEVENHOURS = "7 Hours",
    EIGHTHOURS = "8 Hours",
    NINEHOURS = "9 Hours",
    TENHOURS = "10 Hours",
}

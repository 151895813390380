import {ClothingType} from "./ClothingType";

export const categorizedClothingTypes = [
    {
        category: "General Items",
        items: [
            ClothingType.PANTS,
            ClothingType.TSHIRT,
            ClothingType.SHIRT,
            ClothingType.UNDERWEAR,
            ClothingType.SOCKS,
            ClothingType.BRA,
            ClothingType.SWEATSHIRT,
            ClothingType.JACKET,
            ClothingType.HAT,
            ClothingType.BELT,
            ClothingType.SHOES,
            ClothingType.SANDALS,
            ClothingType.PURSE,
            ClothingType.PAJAMAS,
            ClothingType.BACKPACK,
            ClothingType.DRESS,
        ],

    },
    {
        category: "Hygiene Items",
        items: [
            ClothingType.SHAMPOO_SMALL,
            ClothingType.SHAMPOO_LARGE,
            ClothingType.SOAP_SMALL,
            ClothingType.SOAP_LARGE,
            ClothingType.LOTION_SMALL,
            ClothingType.LOTION_LARGE,
            ClothingType.TOOTHPASTE_SMALL,
            ClothingType.TOOTHPASTE_LARGE,
            ClothingType.TOOTHBRUSH,
            ClothingType.MOUTHWASH,
            ClothingType.DEODORANT,
            ClothingType.QTIPS,
            ClothingType.COMB_BRUSH,
            ClothingType.RAZOR,
            ClothingType.NAIL_CLIPPERS,
            ClothingType.NAIL_FILE,
            ClothingType.LIP_BALM,
            ClothingType.FEMININE_PRODUCTS,
            ClothingType.TISSUE,
            ClothingType.DENTAL_FLOSS,
            ClothingType.HAIR_TIES_CLIPS,
            ClothingType.SUNSCREEN_SMALL,
            ClothingType.SUNSCREEN_LARGE,
            ClothingType.PULL_UPS,
            ClothingType.MASKS,
            ClothingType.SHAVING_CREAM,
            ClothingType.DENTURE_CLEANER,
            ClothingType.FOOT_BABY_POWDER,
            ClothingType.TOILET_PAPER,
            ClothingType.BANDAIDS,
        ],

    },
    {
        category: "Winter Items",
        items: [
            ClothingType.LINED_PANTS,
            ClothingType.THERMAL_UNDERWEAR,
            ClothingType.COAT,
            ClothingType.SCARF,
            ClothingType.LIGHTWEIGHT_GLOVES,
            ClothingType.HEAVY_GLOVES,
            ClothingType.LIGHTWEIGHT_BLANKET,
            ClothingType.HEAVY_BLANKET,
            ClothingType.SLEEPING_BAG,
            ClothingType.RESCUE_BLANKET,
            ClothingType.HAND_TOE_WARMERS,
        ],


    },
    {
        category: "Miscellaneous",
        items: [
            ClothingType.SHOE_STRINGS,
            ClothingType.SUNGLASSES,
            ClothingType.READING_GLASSES,
            ClothingType.CHARGERS,
            ClothingType.WATER_BOTTLES,
            ClothingType.RAIN_PONCHO,
            ClothingType.BED_SHEETS,
            ClothingType.PILLOW,
            ClothingType.HANDKERCHIEF,
            ClothingType.WHEELCHAIR,
            ClothingType.OTHER,
        ],


    },
];